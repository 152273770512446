import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import GlobalLayout from "../generics/layout";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

// @antd

import { message } from "antd";

// @react-router-dom

import { Link } from "react-router-dom";

// @redux 

import { connect } from "react-redux";
import { settings, dashboard } from "../../actions";

import "./styles/index.scss"

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 4,
    "&$checked": {
      transform: "translateX(24px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#219653",
        opacity: 1,
        border: "none",
      },
    },

  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
    

  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: "black",
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "100%",
    padding: 24,
    position: "relative"
  },
  tableRow: {
    background: "#18283B",
    color: "white",
    textAlign: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
  selectedTextDiv: {
    display: "flex",
    overflow: "hidden",
    maxWidth: 290,
  },
  selectedText: {
    margin: "5px",
    marginBlockEnd: "0",
    marginBlockStart: "0"
  }

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const sData = [
  3904,
  3943,
]

function DeviceSetting({
  getAllDevice, 
  allDevices, 
  deleteDevice,
  allowDevice, 
  allowFormDevice,
  fetchFromType, 
  feedbackFormType,
  getTotalDevices,
  totalDevices,
}) {
  const classes = useStyles();
  const [check, setCheck] = useState(false);
  const [alert, setAlert] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formName, setFormName] = useState([]); // Use as selected form 

  // Allow Form 

  const handleFormChange = (event, device) => {
    setFormName(event.target.value);
    setSelectedDevice(device)
    
  };

  useEffect(() => {
    if (formName.length === 0) return;
    if(formName){
      const data = {
        id: selectedDevice && selectedDevice.id,
        feedback_forms: formName,
      }

      allowFormDevice(data)
        .then(res => {
          setFormName([])
          message.success("Updated Forms Allowed Successfully.")
        })
        .catch(err => {
          message.error("Something went wrong.")
        })
      console.log("selected from ", data)
    }
  },[formName])

  // Fetch Data

  useEffect(() => {
    getAllDevice()
    fetchFromType()
    getTotalDevices()
  },[])

  const deleteADevice = (device) => {
    setAlert(true)
    setSelectedDevice(device)
  }

  const confirmDelete = () => {
    if(selectedDevice){
      setLoading(true)
      deleteDevice(selectedDevice.id)
        .then(res => {
          setAlert(false)
          setSelectedDevice(null)
          setLoading(false)
          message.success(`${selectedDevice.model} deleted successfully.`)
        })
        .catch(err => {
          setAlert(false)
          setSelectedDevice(null)
          setLoading(false)
          message.error("something went wrong")
        })
    }
  }

  // Allow OR DisAllow Device 

  const handleChange = (device) => {
    setCheck(!check)
    setSelectedDevice(device)
  }

  useEffect(() => {
    if(selectedDevice){
      const data = {
        authorization: selectedDevice.authorization === true ? false : true 
      }
      allowDevice(selectedDevice.id, data)
        .then(res => {
          message.success(`${selectedDevice.model} updated successfully.`)
        })
        .catch(err => {
          message.error(`something went wrong.`)
        })
    }
  },[check])

  return (
    <GlobalLayout>
      <div className="device__container">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <p className="device__title">
            <span><Link to="/settings">Settings</Link> {'>'} <b>Device Setting</b></span>
            <br/>
            Device Setting
          </p>
          <Box display="flex">
            <p className="device__box__title" style={{marginRight: "15px"}}>
              {totalDevices && totalDevices.total_device}
              <span>Total Devices</span>
            </p>
            <p className="device__box__title" >
              {totalDevices && totalDevices.allowed_device}
              <span>Total Allowed Devies</span>
            </p>
          </Box>
        </Box>
        {console.log("devices", totalDevices && totalDevices)}
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableRow}>Store Name</TableCell>
                <TableCell className={classes.tableRow}>Device Model</TableCell>
                <TableCell className={classes.tableRow}>Model Name</TableCell>
                <TableCell className={classes.tableRow}>
                  Forms Allowed
                </TableCell>
                <TableCell className={classes.tableRow}>App Version</TableCell>
                <TableCell className={classes.tableRow}>Authorized</TableCell>
                <TableCell className={classes.tableRow}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allDevices && allDevices.map((row) => (
                <TableRow key={row.pin}>
                  <TableCell align="center" style={{textTransform: "capitalize"}}>{row.store.name}</TableCell>
                  <TableCell align="center" style={{textTransform: "capitalize"}}>{row.brand}</TableCell>
                  <TableCell align="center" style={{textTransform: "capitalize"}}>{row.model}</TableCell>
                  <TableCell align="center">
                    <FormControl className={classes.formControl}>
                      <Select
                        multiple
                        value={formName.length !== 0 ? formName : row.selected_feedback_form}
                        onChange={(e) => handleFormChange(e, row)}
                        input={<Input />}
                        renderValue={(selected) => (
                          <div className={classes.selectedTextDiv}>
                            {selected.map((item) => {
                              const getSelectedName = feedbackFormType && feedbackFormType.find((el) => el.id === item);
                              // console.log(getSelectedName)
                              return (
                                <p className={classes.selectedText}>
                                  {getSelectedName && getSelectedName.title}
                                  {","}
                                </p>
                              );
                            })}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {feedbackFormType && feedbackFormType.map((item) => (
                          <MenuItem key={item.id} name={item.id} value={item.id}>
                            <Checkbox 
                              checked={
                                formName.length !== 0 
                                ?
                                formName.indexOf(item.id) > -1
                                :
                                row.selected_feedback_form.indexOf(item.id) > -1
                              } 
                            />
                            <ListItemText primary={item.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="center">{row.app_version}</TableCell>
                  <TableCell align="center">
                    <AntSwitch
                      // checked={check}
                      defaultChecked={row.authorization}
                      onChange={() => handleChange(row)}
                      name="checkedC"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => deleteADevice(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {
          alert && 
          <Dialog
            open={alert}
            onClose={() => setAlert(false)}
          > 
            <DialogTitle>
              <div className="dialog__header">
                <span>Deleting a Device</span>
                <IconButton
                  onClick={() => setAlert(false)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                You have selected to delete this device: <b>{selectedDevice && selectedDevice.model}</b> <br />
                This device will be deleted immediately. You can't undo this action.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={() => setAlert(false)} 
                color="primary"
                className="cancel__btn"
                disbaled={loading}
              >
                Cancel
              </Button>
              <Button 
                onClick={confirmDelete} 
                color="primary"
                variant="contained"
                className="delete__btn"
                disbaled={loading}
                startIcon={loading ? <CircularProgress size={15} style={{color: "#fff"}} /> : ""}
              >
                {loading ? "Deleting..." : "Delete Device"}
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    </GlobalLayout>
  );
}

const mapStateToProps = state => ({
  allDevices: state.settings.allDevices,
  feedbackFormType: state.dashboard.feedbackFormType,
  totalDevices: state.settings.totalDevices,
})

const mapDispatchToProps = dispatch => ({
  fetchFromType: () => dispatch(dashboard.fetchFromType()),
  getAllDevice: () => dispatch(settings.getAllDevice()),
  deleteDevice: (id) => dispatch(settings.deleteDevice(id)),
  allowDevice: (id, data) => dispatch(settings.allowDevice(id, data)),
  allowFormDevice: (data) => dispatch(settings.allowFormDevice(data)),
  getTotalDevices: () => dispatch(settings.getTotalDevices())
}) 

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSetting);
