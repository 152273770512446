export const FETCH_ALL_CUSTOMERS = 'FETCH_ALL_CUSTOMERS'
export const FETCH_CUSTOMER_TAGS = 'FETCH_CUSTOMER_TAGS'

export const FETCH_CUSTOMER_DETAILS = 'FETCH_CUSTOMER_DETAILS'
export const FETCH_CUSTOMER_FEEDBACKS = 'FETCH_CUSTOMER_FEEDBACKS'
export const FETCH_CUSTOMER_TRANSACTIONS = 'FETCH_CUSTOMER_TRANSACTIONS'

export const FETCH_CUSTOMER_ANALYSIS = 'FETCH_CUSTOMER_ANALYSIS'
export const EDIT_CUSTOMER_TAG = 'EDIT_CUSTOMER_TAG'

