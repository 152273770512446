import axios from 'axios'; //for request

import {
  GET_ALL_FEEDBACK,
  GET_QUESTIONS_TABLE,
  GET_QUESTIONS_GRAPH,
  GET_OVERALL_RATING_GRAPH,
  GET_VENDOR_TABLE_DATA,
} from '../constants/generateReport';

const BASE_URL = `${process.env.REACT_APP_API_URL}/feedbacktaker/apiv2`;
let headers = { 'Content-Type': 'application/json' }; // Header

export const fetchAllFeedback = (data) => async (dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.post(
        `${BASE_URL}/feedback_reportexport/`,
        data,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_ALL_FEEDBACK, data: response.data.data})
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchVendorTable = (data) => async (dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.post(
        `${BASE_URL}/form_weighted_report/`,
        data,
        { headers }
      )
      
      if(response.status === 200){
        console.log(response.data.data)
        dispatch({ type: GET_VENDOR_TABLE_DATA, data: response.data.data})
      }

    } catch(err) {
      throw err
    }
  }
}


export const fetchOverallRatingGraph = (data) => async (dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.post(
        `${BASE_URL}/ratingreport/`,
        data,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_OVERALL_RATING_GRAPH, data: response.data.data})
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchQuestionsTable = (data) => async (dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.post(
        `${BASE_URL}/customersatisfaction_export/`,
        data,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_QUESTIONS_TABLE, data: response.data.data})
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchQuestionsGraph = (data) => async (dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.post(
        `${BASE_URL}/answer_weighted_analysis_report/`,
        data,
        { headers }
      )
      
      if(response.status === 200){
        console.log("data", response)
        dispatch({ type: GET_QUESTIONS_GRAPH, data: response.data.data})
      }

    } catch(err) {
      throw err
    }
  }
}