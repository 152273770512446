import axios from 'axios'

import {
    FETCH_SMS_TEMPLATES,
    FETCH_SMS_BALANCE,
} from '../constants/sms'

const SMS_BASE_URL = `${process.env.REACT_APP_API_URL}/sms/apiv2`

export const fetchSMSTemplates = () => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.get(
                `${SMS_BASE_URL}/templates/`,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_SMS_TEMPLATES, data: response.data.data })
            }
        }
        catch (error) {
            throw (error)
        }
    }
}

export const fetchSMSBalance = () => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.get(
                `${SMS_BASE_URL}/balance/`,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_SMS_BALANCE, data: response.data.data })
            }
        }
        catch (error) {
            throw (error)
        }
    }
}

export const createSMSTemplate = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.post(
                `${SMS_BASE_URL}/templates/crud/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_SMS_TEMPLATES, data: response.data.data })
            }
        }
        catch (error) {
            throw (error)
        }
    }
}

export const editSMSTemplate = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.put(
                `${SMS_BASE_URL}/templates/crud/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_SMS_TEMPLATES, data: response.data.data })
            }
        }
        catch (error) {
            throw (error)
        }
    }
}

export const deleteSMSTemplate = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.delete(
                `${SMS_BASE_URL}/templates/crud/?${data}`,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_SMS_TEMPLATES, data: response.data.data })
            }
        }
        catch (error) {
            throw (error)
        }
    }
}

export const sendSMSAction = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.post(
                `${SMS_BASE_URL}/send-sms/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw (error)
        }
    }
}