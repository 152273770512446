import {
    ALL_TEMPLATES,
    CATEGORY_TEMPLATES,
    SIMILAR_CATEGORY_TEMPLATES,
    GET_TEMPLATE_DETAIL,
    FORM_CREATED_VIA_TEMPLATE,
    FORM_VIA_TEMPLATE_AFTER_AUTH,
} from '../constants/templates'

const initialState = {
    allTemplates: null,
    categoryTemplates: null,
    similarCategoryTemplates: null,
    templateDetail: null,
    formCreatedViaTemplate: null,
    templateIDFormAfterAuth: null,
}

export default function feedbackFormTemplates(state = initialState, action) {
    switch (action.type) {
        case ALL_TEMPLATES:
            return { ...state, allTemplates: action.data }

        case CATEGORY_TEMPLATES:
            return { ...state, categoryTemplates: action.data }
        
        case SIMILAR_CATEGORY_TEMPLATES:
            return { ...state, similarCategoryTemplates: action.data }

        case GET_TEMPLATE_DETAIL:
            return { ...state, templateDetail: action.data }

        case FORM_CREATED_VIA_TEMPLATE:
            return { ...state, formCreatedViaTemplate: action.data, templateIDFormAfterAuth: null }

        case FORM_VIA_TEMPLATE_AFTER_AUTH:
            return { ...state, templateIDFormAfterAuth: action.data }

        default:
            return state;
    }
}