import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

// @react-redux

import { connect } from "react-redux";
import { settings } from "../../actions";


// @react-router 

import { Link } from "react-router-dom";

// @antd

import { message } from "antd";

import Amico from "../../assets/img/amico.png";

import { brandData, feedbackData, ratingData } from "../ReportPage/data";

import { Grid } from "@material-ui/core";
import GlobalLayout from "../generics/layout";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}));

function ImageSetting({getAllImages, updateImage, allImages}) {
  const classes = useStyles();
  const brandImgRef = useRef([]);
  const feedbackImgRef = useRef([]);
  const ratingImgRef = useRef([]);
  const [isFilePicked, setIsFilePicked] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // Creating multiple ref for brand images

  brandImgRef.current = brandData.map(
    (ref, index) => brandImgRef.current[index] = React.createRef()
  )

  // Creating multiple ref for feedback from images

  feedbackImgRef.current = feedbackData.map(
    (ref, index) => feedbackImgRef.current[index] = React.createRef()
  )

  // Creating multiple ref for rating images

  ratingImgRef.current = ratingData.map(
    (ref, index) => ratingImgRef.current[index] = React.createRef()
  )

  useEffect(() => {
    getAllImages()
  },[])

  const handleFileChange = (e) => {
    console.log(e.target.files)
    console.log("id", e)

    setIsFilePicked(e.target.name)
    setSelectedFile(e.target.files[0])

    const data = new FormData();
    data.append(`title${e.target.id}`, e.target.name);
    data.append(`url${e.target.id}`, e.target.files[0])

    updateImage(data)
      .then(res => {
        message.success("Image updated successfully.")
      })
      .catch(err => {
        message.error("Something went wrong.")
      })

    
  }

  return (
    <GlobalLayout>
      <div className="image__container">
        <p className="image__title">
          <span><Link to="/settings">Settings</Link> {'>'} <b>Image Setting</b></span>
          <br/>
          Image Setting
        </p>
        {/* Brand Image */}

        <div className="brand__container">
          <p className="brand__title">Brand Images</p>
          <Grid container spacing={3} className="brand__grid">
            {brandData.map((item, index) => {
              return(
                <Grid item key={item.id} xs={12} sm={6} md={4} lg={4}>
                  <div className="brand__inner__container">
                    <input 
                      type="file"
                      ref={brandImgRef.current[index]}
                      id={item.id}
                      name={item.key}
                      onChange={handleFileChange}
                      
                      style={{display: "none"}}
                      accept="image/jpeg, image/png"
                    />
                    {
                      isFilePicked == item.key
                      ?
                      <img src={selectedFile &&  URL.createObjectURL(selectedFile)} alt={item.title} />
                      :
                      <img src={allImages && allImages[`url${item.id}`] ? allImages[`url${item.id}`] : Amico} alt="item" />
                    }
                    <p className="brand__type">{item.title}</p>
                    <span 
                      className="brand__anchor"
                      onClick={() => {
                        brandImgRef.current[index].current.click();
                      }}
                    >Upload Now</span>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </div>

        {/* Feedback Form Image */}
        
        <div className="brand__container">
          <p className="brand__title">Feedback Form Images</p>
          <Grid container spacing={3} className="brand__grid">
            {feedbackData.map((item, index) => (
              <Grid item key={item.id} xs={12} sm={6} md={4} lg={4}>
                <div className="brand__inner__container">
                  <input 
                    type="file"
                    ref={feedbackImgRef.current[index]}
                    id={item.id}
                    name={item.key}
                    onChange={handleFileChange}
                    style={{display: "none"}}
                    accept="image/jpeg, image/png"
                  />
                  {
                    isFilePicked == item.key
                    ?
                    <img src={selectedFile &&  URL.createObjectURL(selectedFile)} alt={item.title} />
                    :
                    <img src={allImages && allImages[`url${item.id}`] ? allImages[`url${item.id}`] : Amico} alt="item" />
                  }
                  <p className="brand__type">{item.title}</p>
                  <span 
                    className="brand__anchor"
                    onClick={() => {
                      feedbackImgRef.current[index].current.click();
                    }}
                  >
                    Upload Now
                  </span>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>

        {/* Rating Image */}
        
        <div className="brand__container">
          <p className="brand__title">Rating Images</p>
          <Grid container spacing={5} className="brand__grid">
            {ratingData.map((item, index) => (
              <Grid item key={item.id} xs={12} sm={3} md={2} lg>
                <div className="brand__inner__container">
                  <input 
                    type="file"
                    ref={ratingImgRef.current[index]}
                    id={item.id}
                    name={item.key}
                    onChange={handleFileChange}
                    style={{display: "none"}}
                    accept="image/jpeg, image/png"
                  />
                  {
                    isFilePicked == item.key
                    ?
                    <img 
                      src={selectedFile &&  URL.createObjectURL(selectedFile)} 
                      alt={item.title} 
                      style={{
                        minHeight: "200px",
                        maxHeight: "200px",
                        maxWidth: "200px"
                      }}
                    />
                    :
                    <img 
                      src={allImages && allImages[`url${item.id}`] ? allImages[`url${item.id}`] : Amico} 
                      alt={item.title}
                      style={{
                        minHeight: "200px",
                        maxHeight: "200px",
                        maxWidth: "200px"
                      }} 
                    />
                  }
                  <p className="brand__type">{item.title}</p>
                  <span 
                    className="brand__anchor"
                    onClick={() => {
                      ratingImgRef.current[index].current.click();
                    }}
                  >
                    Upload Now
                  </span>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>


      </div>
    </GlobalLayout>
  );
}

const mapStateToProps = state => ({
  allImages: state.settings.allImages,
})

const mapDispatchToProps = dispatch => ({
  getAllImages: () => dispatch(settings.getAllImages()),
  updateImage: (data) => dispatch(settings.updateImage(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageSetting)
