export const CREATE_FEEDBACK_FORM = 'CREATE_FEEDBACK_FORM'
export const ALL_FEEDBACK_FORM = 'ALL_FEEDBACK_FORM'
export const PAGINATED_FEEDBACK_FORMS = 'PAGINATED_FEEDBACK_FORMS'
export const FEEDBACK_FORM_STATUS = 'FEEDBACK_FORM_STATUS'

export const ALL_QUESTIONS = 'ALL_QUESTIONS'
export const FEEDBACK_FORM_DETAIL = 'FEEDBACK_FORM_DETAIL'
export const ADD_QUESTION = 'ADD_QUESTION'
export const EDIT_QUESTION_OPTION = 'EDIT_QUESTION_OPTION'
export const UPDATED_FEEDBACK_FORM = 'UPDATED_FEEDBACK_FORM'

export const SCREEN_INFO_SETTINGS = 'SCREEN_INFO_SETTINGS'
export const CUSTOMER_INFO_SETTINGS = 'CUSTOMER_INFO_SETTINGS'
export const BILL_INFO_SETTINGS = 'BILL_INFO_SETTINGS'

export const ALL_TEMPLATES = 'ALL_TEMPLATES'

export const FETCH_FEEDBACK_TAKERS = 'FETCH_FEEDBACK_TAKERS'

export const FETCH_FEEDBACK_AUTOMATION = 'FETCH_FEEDBACK_AUTOMATION'

export const FETCH_FEEDBACK_CUSTOMER_NOTIFICATION = 'FETCH_FEEDBACK_CUSTOMER_NOTIFICATION'

export const DELETE_FEEDBACK_CUSTOMER_NOTIFICATION = 'DELETE_FEEDBACK_CUSTOMER_NOTIFICATION'