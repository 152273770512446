import {
    FETCH_FEEDBACK_LIST,
    FETCH_FEEDBACK_FORMS_LIST,
    FETCH_CUSTOM_TABLE_FILTERS,
    FETCH_CUSTOM_TABLE_FILTER,
    FETCH_CUSTOM_TABLE_FIELDS,
    FETCH_FEEDBACK_DETAILS,
    FETCH_FEEDBACK_DETAILS_ANSWERS,
    FETCH_FEEDBACK_DETAILS_TASKS,
} from '../constants/feedbacks'

const initialState = {
    feedbacks: null,
    feedbackDetails: null,
    feedbackForms: null,
    customTableFilter: null,
    customTableFilterDetail: null,
    customFieldNames: null,
    answers: null,
    tasks: null,
}

export default function feedbacks(state = initialState, action) {
    switch (action.type) {
        case FETCH_FEEDBACK_LIST:
            return { ...state, feedbacks: action.data }

        case FETCH_FEEDBACK_DETAILS:
            return { ...state, feedbackDetails: action.data }

        case FETCH_FEEDBACK_FORMS_LIST:
            return { ...state, feedbackForms: action.data }

        case FETCH_CUSTOM_TABLE_FILTERS:
            return { ...state, customTableFilter: action.data }

        case FETCH_CUSTOM_TABLE_FILTER:
            return { ...state, customTableFilterDetail: action.data }

        case FETCH_CUSTOM_TABLE_FIELDS:
            return { ...state, customFieldNames: action.data }

        case FETCH_FEEDBACK_DETAILS_ANSWERS:
            return { ...state, answers: action.data, }

        case FETCH_FEEDBACK_DETAILS_TASKS:
            return { ...state, tasks: action.data }
        default:
            return state

    }

}