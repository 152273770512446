import {
  GET_ALL_TEXT,
  GET_ALL_DEVICE,
  GET_ALL_FORM,
  GET_ALL_IMAGES,
  GET_ALL_CARDS_FOR_TABLE,
  GET_ALL_CARDS_STATUS,
  GET_TOTAL_DEVICES,
} from '../constants/settings';

const initialState = {
  textSetting: null,
  allDevices: null,
  allForm: null,
  allImages: null,
  cardsForTable: null,
  cardsStatus: null,
  totalDevices: null,
}

export default function settings(state = initialState, action){
  switch(action.type){

    case GET_ALL_TEXT:
      return { ...state, textSetting: action.data }

    case GET_ALL_DEVICE: 
      return { ...state, allDevices: action.data }

    case GET_ALL_FORM:
      return { ...state, allForm: action.data}

    case GET_ALL_IMAGES:
      return { ...state, allImages: action.data }

    case GET_ALL_CARDS_FOR_TABLE:
      return { ...state, cardsForTable: action.data }

    case GET_ALL_CARDS_STATUS: 
      return { ...state, cardsStatus: action.data }

    case GET_TOTAL_DEVICES:
      return { ...state, totalDevices: action.data}

    default:
      return state;

  }
}