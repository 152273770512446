import axios from 'axios'
import {
    FETCH_CREDENTIALS,
    FETCH_CONNECTED_ACCOUNTS,
    FETCH_REVIEW_LIST,
    PAGINATED_ZOMATO_REVIEWS,
    FETCH_ZOMATO_REVIEW_ANALYSIS,
    PAGINATED_ZOMATO_KEYPHRASES,
    FETCH_ZOMATO_KEYPHRASES_LIST,

    FETCH_GOOGLE_REVIEW_LIST,
    PAGINATED_GOOGLE_REVIEWS,
    FETCH_GOOGLE_REVIEW_ANALYSIS,
    FETCH_GOOGLE_KEYPHRASES_LIST,
    PAGINATED_GOOGLE_KEYPHRASES,

    FETCH_FACEBOOK_REVIEW_LIST,
    PAGINATED_FACEBOOK_REVIEWS,
    FETCH_FACEBOOK_REVIEW_ANALYSIS,
    FETCH_FACEBOOK_KEYPHRASES_LIST,
    PAGINATED_FACEBOOK_KEYPHRASES,

    FETCH_INSTAGRAM_REVIEW_LIST,
    PAGINATED_INSTAGRAM_REVIEWS,
    FETCH_INSTAGRAM_REVIEW_ANALYSIS,
    FETCH_INSTAGRAM_KEYPHRASES_LIST,
    PAGINATED_INSTAGRAM_KEYPHRASES,

    FETCH_TWITTER_MENTIONS_LIST,
    PAGINATED_TWITTER_MENTIONS,
    FETCH_TWITTER_REVIEW_ANALYSIS,
    FETCH_TWITTER_KEYPHRASES_LIST,
    PAGINATED_TWITTER_KEYPHRASES,

    FETCH_OVERALL_REPORT,

} from '../constants/reviews'

const REVIEW_API_URL = `${process.env.REACT_APP_API_URL}/reviews/apiv2`

export const googleOAuthCallback = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/google/callback/`,
                data,
                { headers }
            )
            
        }
        catch (error) {
            throw error
        }


    }
}

export const instagramOAuthCallback = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/instagram/callback/`,
                data,
                { headers }
            )
            
        }
        catch (error) {
            throw error
        }


    }
}

export const fetchCredentials = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/credentials`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_CREDENTIALS, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const createZomatoStoreCredentials = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/zomato-store-credentials/`,
                data,
                { headers }
            )
            if (response.status === 200) {
                // dispatch({ type: FETCH_CREDENTIALS, data: response.data.data })
                
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchConnectedAccounts = () => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/connected-accounts/`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_CONNECTED_ACCOUNTS, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const collectReviews = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/reviews/`,
                data,
                { headers }
            )
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchReviewList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/reviews/list/${data}`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_REVIEW_LIST, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedZomatoReviews = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: PAGINATED_ZOMATO_REVIEWS, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchZomatoReviewAnalysis = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/zomato/report/?zomato_store_id=${data}`,
                { headers }
            )

            if (response.status === 200) {
                
                dispatch({ type: FETCH_ZOMATO_REVIEW_ANALYSIS, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchZomatoKeyPhraseList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/zomato/keyphrases/?zomato_store_id=${data}`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_ZOMATO_KEYPHRASES_LIST, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedZomatoKeyPhraseList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: PAGINATED_ZOMATO_KEYPHRASES, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchGoogleLocationsData = () => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/google/locations/`,
                { headers }
            )
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const createGoogleLocationsData = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/google/locations/`,
                data,
                { headers }
            )
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const collectGoogleReviews = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/google/reviews/`,
                data,
                { headers }
            )
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchGoogleReviewList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/google/reviews/list/${data}`,
                { headers }
            )
            
            if (response.status === 200) {
                dispatch({ type: FETCH_GOOGLE_REVIEW_LIST, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedGoogleReviews = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: PAGINATED_GOOGLE_REVIEWS, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}



export const fetchGoogleReviewAnalysis = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/google/report/?google_location_id=${data}`,
                { headers }
            )

            if (response.status === 200) {
                
                dispatch({ type: FETCH_GOOGLE_REVIEW_ANALYSIS, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchGoogleKeyPhraseList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/google/keyphrases/?google_location_id=${data}`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_GOOGLE_KEYPHRASES_LIST, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedGoogleKeyPhraseList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: PAGINATED_GOOGLE_KEYPHRASES, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const createFacebookCredentials = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/facebook/credentials/`,
                data,
                { headers }
            )
            if (response.status === 200) {
                // dispatch({ type: FETCH_GOOGLE_REVIEW_LIST, data: response.data })
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchFacebookCredentials = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/facebook/reviews/`,
                data,
                { headers }
            )
            if (response.status === 200) {
                // dispatch({ type: FETCH_GOOGLE_REVIEW_LIST, data: response.data })
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchFacebookReviewList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/facebook/reviews/list/${data}`,
                { headers }
            )
            
            if (response.status === 200) {
                dispatch({ type: FETCH_FACEBOOK_REVIEW_LIST, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedFacebookReviews = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: PAGINATED_FACEBOOK_REVIEWS, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchFacebookReviewAnalysis = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/facebook/report/?facebook_page_id=${data}`,
                { headers }
            )

            if (response.status === 200) {
                
                dispatch({ type: FETCH_FACEBOOK_REVIEW_ANALYSIS, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchFacebookKeyPhraseList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/facebook/keyphrases/?facebook_page_id=${data}`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_FACEBOOK_KEYPHRASES_LIST, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedFacebookKeyPhraseList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: PAGINATED_FACEBOOK_KEYPHRASES, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}
// //////

export const fetchInstagramPageAndMentions = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/instagram/pages/`,
                data,
                { headers }
            )
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchInstagramReviewList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/instagram/reviews/list/${data}`,
                { headers }
            )
            
            if (response.status === 200) {
                dispatch({ type: FETCH_INSTAGRAM_REVIEW_LIST, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedInstagramReviews = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: PAGINATED_INSTAGRAM_REVIEWS, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchInstagramReviewAnalysis = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/instagram/report/?insta_page_id=${data}`,
                { headers }
            )

            if (response.status === 200) {
                
                dispatch({ type: FETCH_INSTAGRAM_REVIEW_ANALYSIS, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchInstagramKeyPhraseList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/instagram/keyphrases/?insta_page_id=${data}`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_INSTAGRAM_KEYPHRASES_LIST, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedInstagramKeyPhraseList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: PAGINATED_INSTAGRAM_KEYPHRASES, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}
// /////////////

export const fetchTwitterOAuthUrl = () => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/twitter/oauth-url/`,
                {},
                { headers }
            )
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const twitterAuthenticate = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/twitter/authenticate/`,
                data,
                { headers }
            )
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchTwitterMentions = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.post(
                `${REVIEW_API_URL}/twitter/mentions/`,
                data,
                { headers }
            )
            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchTwitterMentionsList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/twitter/mentions/list/${data}`,
                { headers }
            )
            
            if (response.status === 200) {
                dispatch({ type: FETCH_TWITTER_MENTIONS_LIST, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedTwitterMentions = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: PAGINATED_TWITTER_MENTIONS, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchTwitterMentionAnalysis = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/twitter/report/`,
                { headers }
            )

            if (response.status === 200) {
                
                dispatch({ type: FETCH_TWITTER_REVIEW_ANALYSIS, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchTwitterKeyPhraseList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${REVIEW_API_URL}/twitter/keyphrases/`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_TWITTER_KEYPHRASES_LIST, data: response.data })
                return
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedTwitterKeyPhraseList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: PAGINATED_TWITTER_KEYPHRASES, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchOverallAnalysis = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.get(
                `${REVIEW_API_URL}/overall-report/`,
                { headers },
            )
            if (response.status === 200) {
                
                dispatch({ type: FETCH_OVERALL_REPORT, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

