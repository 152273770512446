import {
    FETCH_IMAGES
} from '../constants/contentStudio'


const initialState = {
    images: null,
    files: null,
}

export default function contentStudio(state = initialState, action) {
    switch (action.type) {
        case FETCH_IMAGES:
            return { ...state, images: action.data.images, files: action.data.files }

        default:
            return state
    }
}