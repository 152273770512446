import axios from "axios";
import {
  GET_SUBSCRIBER
} from "../constants/subscription"

const SUBSCRIPTION_BASE_URL = `${process.env.REACT_APP_API_URL}/dashboard/apiv2`;

export const fetchSubscribers = () => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.get(
            `${SUBSCRIPTION_BASE_URL}/add_subscriber/`,
            { headers },
        )
        if (response.status === 200) {
          dispatch({ type: GET_SUBSCRIBER, data: response.data.data })
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

export const addSubscribers = (data) => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.post(
            `${SUBSCRIPTION_BASE_URL}/add_subscriber/`,
            data,
            { headers },
        )
        if (response.status === 200 || response.status === 201) {
          dispatch(fetchSubscribers())
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

export const activateSubscriber = (id, data) => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.put(
            `${SUBSCRIPTION_BASE_URL}/subscriber_details_api/${id}`,
            data,
            { headers },
        )
        if (response.status === 200 || response.status === 201) {
          dispatch(fetchSubscribers())
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

export const deleteSubscriber = (id) => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.delete(
            `${SUBSCRIPTION_BASE_URL}/subscriber_details_api/${id}`,
            { headers },
        )
        if (response.status === 200 || response.status === 201) {
          dispatch(fetchSubscribers())
        }
    }
    catch (error) {
        throw (error)
    }
  }
}