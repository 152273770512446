import axios from "axios";
import { GET_FEEDBACK_FORM } from "../constants/response";

export const getFormForResponse = (data) => async (dispatch) => {
  let headers = {
    "Content-Type": "application/json",
  };

  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/feedbacks/apiv2/response/feedback-form/`,
      { headers, params: data }
    );

    if (response.status === 200) {
      return dispatch({
        type: GET_FEEDBACK_FORM,
        data: response.data.data,
        store: response.data.store,
        feedbacker: response.data.feedbacker,
        npsAction: response.data.action,
      });
    }
    if (response.status === 220) {
      dispatch({
        type: GET_FEEDBACK_FORM,
        data: response.data.data,
        store: response.data.store,
        feedbacker: response.data.feedbacker,
        forceThankyouScreen: true,
      });
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const getTemplateFeedbackForm = (data) => async (dispatch) => {
  let headers = {
    "Content-Type": "application/json",
  };

  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/feedbacks/apiv2/templates/get-template-form/?template_form_id=${data}`,
      { headers }
    );

    if (response.status === 200) {
      return dispatch({
        type: GET_FEEDBACK_FORM,
        data: response.data.data,
        store: response.data.store,
      });
    }
  } catch (error) {
    throw error;
  }
};
