import axios from 'axios';
import {
  GET_TOTAL_FEEDBACKS,
  GET_TOTAL_CUSTOMER,
  GET_TOTAL_LOYALITY,
  GET_TOTAL_CAMPAIGN,
  GET_TOTAL_REFFERALS,
  GET_TOTAL_NEGATVIE_FEEDBACK,
  GET_UPCOMING_EVENTS,
  GET_WEIGHTED_GRAPH,
  GET_NON_WEIGHTED_GRAPH,
  GET_FEEDBACK_FORM_TYPE,
  GET_SATISFACTION_SCORE,
  GET_RETENTION_SCORE,
} from '../constants/dashboard'

const DASHBOARD_BASE_URL = `${process.env.REACT_APP_API_URL}/dashboard`
// const DASHBOARD_BASE_URL = `${process.env.REACT_APP_API_URL}/dashboard`

/**
 * Filter data according to the range 
 * have to hit all api with "start" and "end" params 
 * @param {* start_data from range picker} start 
 * @param {* end_date from range picker} end 
 * @returns filtered data
 * 
 */

export const filterDataByRange = (start, end) => async (dispatch, getState) => {
  let token = getState().auth.token;
  if(token && start && end){
    dispatch(fetchTotalFeedbacks(start, end))
    dispatch(fetchTotalCustomers(start, end))
    dispatch(fetchTotalLoyality(start, end))
    dispatch(fetchTotalCampaign(start, end))
    dispatch(fetchTotalRefferal(start, end))
    dispatch(fetchTotalNegativeFeedback(start, end))
    dispatch(fetchUpcomingEvents(start, end))
    dispatch(fetchSatisfactionChartScore(start, end))
    dispatch(fetchRetentionChartScore(start, end))
  }
} 

/**
 * On range picker clear 
 * @returns last 30 days data by default 
 */

export const onRangeClear = () => async (dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    dispatch(fetchTotalFeedbacks())
    dispatch(fetchTotalCustomers())
    dispatch(fetchTotalLoyality())
    dispatch(fetchTotalCampaign())
    dispatch(fetchTotalRefferal())
    dispatch(fetchTotalNegativeFeedback())
    dispatch(fetchUpcomingEvents())
    dispatch(fetchSatisfactionChartScore())
    dispatch(fetchRetentionChartScore())
  }
}

export const fetchTotalFeedbacks = (start, end) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${DASHBOARD_BASE_URL}/apiv2/all-feedback/${start && end ? `?start=${start}&end=${end}` :''}`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_TOTAL_FEEDBACKS, data: response.data.data })
        
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchTotalCustomers = (start, end) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${DASHBOARD_BASE_URL}/apiv2/all-customer/${start && end ? `?start=${start}&end=${end}` :''}`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_TOTAL_CUSTOMER, data: response.data.data })
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchTotalLoyality = (start, end) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${DASHBOARD_BASE_URL}/apiv2/all-loyalty/${start && end ? `?start=${start}&end=${end}` :''}`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_TOTAL_LOYALITY, data: response.data.data })
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchTotalCampaign = (start, end) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${DASHBOARD_BASE_URL}/apiv2/all-campaign/${start && end ? `?start=${start}&end=${end}` :''}`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_TOTAL_CAMPAIGN, data: response.data.data })
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchTotalRefferal = (start, end) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${DASHBOARD_BASE_URL}/apiv2/all-refferal/${start && end ? `?start=${start}&end=${end}` :''}`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_TOTAL_REFFERALS, data: response.data.data })
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchTotalNegativeFeedback = (start, end) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${DASHBOARD_BASE_URL}/apiv2/all-negative-feedback/${start && end ? `?start=${start}&end=${end}` :''}`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_TOTAL_NEGATVIE_FEEDBACK, data: response.data.data })
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchUpcomingEvents = (start, end) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${DASHBOARD_BASE_URL}/apiv2/all-upcoming-events/${start && end ? `?start=${start}&end=${end}` :''}`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_UPCOMING_EVENTS, data: response.data.data })
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchFromType = () => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/feedbacks/apiv2/store/feedback-forms/?paginate=false`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_FEEDBACK_FORM_TYPE, data: response.data})
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchWeightedGraph = (data) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${DASHBOARD_BASE_URL}/apiv2/weighted-analysis/?feedback_form=${data}`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_WEIGHTED_GRAPH, data: response.data})
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchNonWeightedGraph = (data) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${DASHBOARD_BASE_URL}/apiv2/non-weighted-analysis/?feedback_form=${data}`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_NON_WEIGHTED_GRAPH, data: response.data.data})
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchSatisfactionChartScore = (start, end) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${DASHBOARD_BASE_URL}/apiv2/customer-satisfaction-score/${start && end ? `?start=${start}&end=${end}` :''}`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_SATISFACTION_SCORE, data: response.data.data })
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchRetentionChartScore = (start, end) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${DASHBOARD_BASE_URL}/apiv2/retention-score/${start && end ? `?start=${start}&end=${end}` :''}`,
        { headers }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_RETENTION_SCORE, data: response.data.data })
      }

    } catch(err) {
      throw err
    }
  }
}