export const FETCH_ALL_MEMBERS = 'FETCH_ALL_MEMBERS'
export const FETCH_MEMBERS_STAT = 'FETCH_MEMBERS_STAT'
export const FETCH_PLAN_TYPE = 'FETCH_PLAN_TYPE'
export const ADD_PRIMARY_MEMBER = 'ADD_PRIMARY_MEMEBR'
export const FETCH_COUPON_DETAILS = 'FETCH_COUPON_DETAILS'
export const COUPONS_MEMBERS_LIST ='COUPONS_MEMBERS_LIST'
export const FETCH_MEMBER_DETAILS ='FETCH_MEMBER_DETAILS'
export const FETCH_DOCUMENTS ='FETCH_DOCUMENTS'
export const FETCH_SECONDARY_MEMBERS ='FETCH_SECONDARY_MEMBERS'

