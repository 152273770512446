import axios from 'axios'
import {
    FETCH_IMAGES,
} from '../constants/contentStudio'

const BASE_URL = `${process.env.REACT_APP_API_URL}/company/apiv2`

export const fetchImages = () => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }

        try {
            let response = await axios.get(
                `${BASE_URL}/content-storage/`,
                { headers }
            )

            if (response.status === 200) {
                dispatch({type: FETCH_IMAGES, data: response.data.data})
            }
        }
        catch (error) {
            throw error
        }
    }
}

// export const fetchFiles = () => async (dispatch, getState) => {
//     const token = getState().auth.token
//     if (token) {
//         const headers = {
//             'Content-Type': 'application/json',
//             'Authorization': `Token ${token}`,
//         }

//         try {
//             let response = await axios.get(
//                 `${BASE_URL}/content-storage/`,
//                 { headers }
//             )

//             if (response.status === 200) {
//                 dispatch({type: FETCH_IMAGES, data: response.data.data})
//             }
//         }
//         catch (error) {
//             throw error
//         }
//     }
// }

export const uploadImage = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Token ${token}`,
        }

        try {
            let response = await axios.post(
                `${BASE_URL}/content-storage/images/`,
                data,
                { headers }
            )

            if (response.status === 200) {
                dispatch({type: FETCH_IMAGES, data: response.data.data})
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const uploadFile = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Token ${token}`,
        }

        try {
            let response = await axios.post(
                `${BASE_URL}/content-storage/files/`,
                data,
                { headers }
            )

            if (response.status === 200) {
                dispatch({type: FETCH_IMAGES, data: response.data.data})
            }
        }
        catch (error) {
            throw error
        }
    }
}