import {
    FETCH_EMAIL_REPORTS_LIST,
    FETCH_EMAIL_REPORT,
    FETCH_EMAIL_REPORT_DETAILS,
    FETCH_PAGINATED_EMAIL_REPORT_DETAILS,
    FETCH_EMAIL_SUMMARY,
    FETCH_PAGINATED_EMAIL_REPORTS_LIST,
    FETCH_SCHEDULED_EMAILS,
} from '../constants/reports'


const initialState = {
    emailCampaignReport: null,
    emailCampaignReportsList: null,
    emailCampaignReportsDetails: null,
    reportSummary: null,
    scheduledEmails: null,
}

export default function reports(state = initialState, action) {
    switch (action.type) {
        case FETCH_EMAIL_REPORTS_LIST:
            return { ...state, emailCampaignReportsList: action.data }

        case FETCH_PAGINATED_EMAIL_REPORTS_LIST:
            return {
                ...state,
                emailCampaignReportsList:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.emailCampaignReportsList.results, ...action.data.results]
                }
            }

        case FETCH_EMAIL_REPORT:
            return { ...state, emailCampaignReport: action.data }

        case FETCH_EMAIL_REPORT_DETAILS:
            return { ...state, emailCampaignReportsDetails: action.data }

        case FETCH_PAGINATED_EMAIL_REPORT_DETAILS:
            return {
                ...state,
                emailCampaignReportsDetails:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.emailCampaignReportsDetails.results, ...action.data.results]
                }
            }

        case FETCH_EMAIL_SUMMARY:
            return {
                ...state,
                reportSummary: action.data,
            }

        case FETCH_SCHEDULED_EMAILS: 
            return {
                ...state, 
                scheduledEmails: action.data
            }

        default:
            return state
    }
}