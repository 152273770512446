export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'

export const USER_LOADING = 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'

export const LOADING = 'LOADING'
export const LOADED = 'LOADED'

export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL'

export const REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL'
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED'

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS' 
export const FETCH_STORE_CONFIG = 'FETCH_STORE_CONFIG'