import { GET_FEEDBACK_FORM } from "../constants/response";

const initialState = {
  feedbackForm: null,
  store: null,
  feedbacker: null,
  forceThankyouScreen: false,
  npsAction: null
};

export default function response(state = initialState, action) {
  switch (action.type) {
    case GET_FEEDBACK_FORM:
    console.log(" action.npsAction",  action.npsAction)

      return {
        ...state,
        feedbackForm: action.data,
        store: action.store,
        feedbacker: action.feedbacker,
        forceThankyouScreen: action.forceThankyouScreen,
        npsAction: action.npsAction,
      };

    default:
      return state;
  }
}
