export const SITENAME = "FellaFeeds";

export const DESCRIPTION =
  "Powerful Fast and Efficient Customer Feedback app to take customer feedback digitally through tablet, smartphones &amp; iphone this customer feedback app helps in increasing online customer review.";

export const SideMenu = [
  {
    key: 1,
    icon: "dashboard",
    name: "Dashboard",
    link: `/dashboard/`,
    external: true,
  },
  {
    key: 2,
    icon: "team",
    name: "Employee",
    link: `/employee/`,
    external: true,
  },
  {
    key: 3,
    icon: "form",
    name: "Feedback Forms",
    link: "/feedback-forms/",
    external: false,
  },
  {
    key: 4,
    icon: "smile",
    name: "Feedbacks",
    link: "/feedbacks/",
    external: false,
  },
  {
    key: "reviews",
    icon: "trophy",
    name: "Reviews",
    link: `/reviews/`,
    external: false,
  },
  {
    key: 5,
    icon: "user",
    name: "Customers",
    link: `/customers/`,
    external: false,
  },
  {
    key: "emailTemplate",
    icon: "mail",
    name: "Email Templates",
    link: `/customers/campaigns/email/`,
    external: false,
  },
  // {
  //     key: 6,
  //     icon: 'message',
  //     name: 'Ask Feedback',
  //     link: `${process.env.REACT_APP_API_URL}/dashboard/feedback-request/`,
  //     external: true,
  // },

  {
    key: 6,
    icon: "team",
    name: "Members",
    link: `/members/`,
    external: true,
  },
  {
    key: 7,
    icon: "shop",
    name: "Stores",
    link: `${process.env.REACT_APP_API_URL}/dashboard/stores/`,
    external: true,
  },
  {
    key: 8,
    icon: "file_done",
    name: "Reports",
    link: `/report-page/`,
    // link: `${process.env.REACT_APP_API_URL}/dashboard/export/`,
    external: true,
  },
  {
    key: 9,
    icon: "mail",
    name: "Promotional Sms",
    link: `${process.env.REACT_APP_API_URL}/dashboard/campaign/sms/`,
    external: true,
  },
  {
    key: 10,
    icon: "gift",
    name: "Loyalty Program",
    link: `${process.env.REACT_APP_API_URL}/dashboard/loyalty/`,
    external: true,
  },
  {
    key: 11,
    icon: "share_alt",
    name: "Referral Program",
    link: `${process.env.REACT_APP_API_URL}/dashboard/referprogram/`,
    external: true,
  },
  {
    key: 12,
    icon: "dollar",
    name: "Voucher Program",
    link: `${process.env.REACT_APP_API_URL}/dashboard/voucher/`,
    external: true,
  },
  {
    key: 13,
    icon: "like",
    name: "Testimonial",
    link: `${process.env.REACT_APP_API_URL}/dashboard/review_page/`,
    external: true,
  },
  {
    key: 14,
    icon: "info",
    name: "Login Info",
    link: `${process.env.REACT_APP_API_URL}/dashboard/logininfo/`,
    external: true,
  },
];

export const FeedbackFormTypes = [
  {
    key: 1,
    name: "Start From Scratch",
  },
  {
    key: 2,
    name: "Start From Template",
  },
  {
    key: 3,
    name: "My Feedback Forms",
  },
];

export const FormFormat = [
  {
    key: "one-ques",
    name: "One question at a time",
    icon: "tablet",
    icons: false,
  },
  {
    key: "classic",
    name: "Single Page",
    icon: "menu",
    icons: false,
  },
  // {
  //     key: 'chat',
  //     name: 'Conversation',
  //     icon: 'message',
  //     icons: false,
  // },
  // {
  //     key: 'checklist',
  //     name: 'Checklist',
  //     icon: 'check-square',
  //     icons: false,
  // },
  // {
  //     key: 'open-feedback',
  //     name: 'Open feedback',
  //     icons: true,
  //     ui_icons: ['smile', 'meh', 'frown']
  // }
];

export const QuestionType = [
  {
    key: 1,
    question_type_key: 1,
    question_type: "MCQ",
    name: "Multiple Choice Question",
    icon: "ordered-list",
    rated: true,
    options: true,
    disabled: false,
  },
  {
    key: 2,
    question_type_key: 3,
    question_type: "RATING",
    name: "Rating",
    icon: "star",
    rated: true,
    options: true,
    disabled: false,
  },
  {
    key: 3,
    question_type_key: 4,
    question_type: "CHAR",
    name: "Single Line Text",
    icon: "font-colors",
    rated: false,
    options: false,
    disabled: false,
  },
  {
    key: 4,
    question_type_key: 5,
    question_type: "TEXT",
    name: "Text Box",
    icon: "file-text",
    rated: false,
    options: false,
    disabled: false,
  },
  {
    key: 5,
    question_type_key: 6,
    question_type: "NUMBER",
    name: "Number",
    icon: "field-binary",
    rated: false,
    options: false,
    disabled: false,
  },
  {
    key: 6,
    question_type_key: 7,
    question_type: "YES/NO",
    name: "Yes/No",
    icon: "check",
    rated: true,
    options: true,
    disabled: false,
  },
  {
    key: 7,
    question_type_key: 8,
    question_type: "MCQ_NON_RATING",
    name: "MCQ Non Rated",
    icon: "unordered-list",
    rated: false,
    options: true,
    disabled: false,
  },
  {
    key: 8,
    question_type_key: 9,
    question_type: "DROPDOWN",
    name: "Dropdown",
    icon: "down-square",
    rated: false,
    options: true,
    disabled: false,
  },
  {
    key: 9,
    question_type_key: 10,
    question_type: "CHECKBOXES",
    name: "Checkboxes",
    icon: "check-square",
    rated: false,
    options: true,
    disabled: true,
  },
  {
    key: 10,
    question_type_key: 11,
    question_type: "SINGLE_IMAGE_SELECT",
    name: "Single Image Select",
    icon: "picture",
    rated: true,
    options: true,
    disabled: false,
  },
  {
    key: 11,
    question_type_key: 12,
    question_type: "MULTIPLE_IMAGES_SELECT",
    name: "Multiple Image Select",
    icon: "file-image",
    rated: false,
    options: true,
    disabled: true,
  },
  {
    key: 12,
    question_type_key: 13,
    question_type: "DATE",
    name: "Date",
    icon: "calendar",
    rated: false,
    options: false,
    disabled: false,
  },
  {
    key: 13,
    question_type_key: 15,
    question_type: "IMAGE_TYPE",
    name: "Image Type",
    icon: "picture",
    rated: false,
    options: false,
    disabled: false,
  },
  {
    key: 14,
    question_type_key: 16,
    question_type: "DATE_RANGE",
    name: "Date Range",
    icon: "calendar",
    rated: false,
    options: false,
    disabled: false,
  },
  {
    key: 15,
    question_type_key: 17,
    question_type: "CUSTOMER_INFO",
    name: "Customer Information",
    icon: "user",
    rated: false,
    options: false,
    disabled: false,
  },
  {
    key: 16,
    question_type_key: 18,
    question_type: "BILL_INFO",
    name: "Bill Information",
    icon: "wallet",
    rated: false,
    options: false,
    disabled: false,
  },
  {
    key: 17,
    question_type_key: 19,
    question_type: "NPS",
    name: "Net Promoter Score",
    icon: "dashboard",
    rated: true,
    options: false,
    disabled: false,
  },
  {
    key: 18,
    question_type_key: 20,
    question_type: "INFORMATION",
    name: "Information Screen",
    icon: "info",
    rated: true,
    options: false,
    disabled: false,
  },
];

export const FeedbackFormCreateSider = [
  {
    key: "create",
    name: "Create",
    icon: "build",
  },
  {
    key: "settings",
    name: "Settings",
    icon: "setting",
  },
  {
    key: "appearance",
    name: "Appearance",
    icon: "edit",
  },
  {
    key: "share",
    name: "Share",
    icon: "share-alt",
  },
  {
    key: "notification",
    name: "Notification",
    icon: "notification",
  },
];

export const SharingCreateSider = [
  {
    key: 1,
    name: "Send link on mail",
    icon: "mail",
  },
  {
    key: 2,
    name: "Send link on SMS",
    icon: "message",
  },
  {
    key: 3,
    name: "Generate a QR code",
    icon: "Qrcode",
  },
  {
    key: 4,
    name: "Share on Facebook(Coming soon)",
    icon: "facebook",
  },
];

export const ActionToolbarData = [
  {
    key: 1,
    name: "Send SMS",
    icon: "message",
  },
];

export const NavBarMenu = [
  {
    key: "softwares",
    name: "Softwares",
    url: "",
    icon: "home",
    internal: false,
    extra: true,
  },
  {
    key: "form-templates",
    name: "Form Templates",
    url: "/feedback-form-templates/",
    icon: "",
    internal: true,
    extra: false,
  },
  {
    key: "industies",
    name: "Industies",
    url: "",
    icon: "",
    internal: false,
    extra: true,
  },
  {
    key: "blogs",
    name: "Blogs",
    url: "http://fellafeeds.com/blogs/",
    icon: "",
    internal: false,
    extra: false,
  },
  {
    key: "redeem-points",
    name: "Redeem Points",
    url: "https://fellafeeds.com/userfeed",
    icon: "",
    internal: false,
    extra: false,
  },
  {
    key: "request-a-demo",
    name: "Request a Demo",
    url: "http://fellafeeds.com/request-a-demo",
    icon: "",
    internal: false,
    extra: false,
  },
];

export const TopbarSoftwareLinks = [
  {
    key: "Android devices",
    name: "Android devices",
    url: "https://fellafeeds.com/feedback-app-for-android-device",
  },
  {
    key: "Email Marketing",
    name: "Email Marketing",
    url: "https://fellafeeds.com/email-marketing",
  },
  {
    key: "iOS devices",
    name: "iOS devices",
    url: "https://fellafeeds.com/feedback-app-for-apple-ios-iphone-ipad",
  },
  {
    key: "Kiosk",
    name: "Kiosk",
    url: "https://fellafeeds.com/feedback-app-for-kiosk",
  },
  {
    key: "Web Survey",
    name: "Web Survey",
    url: "https://fellafeeds.com/online-survey-tool",
  },
  {
    key: "SMS Survey",
    name: "SMS Survey",
    url: "https://fellafeeds.com/sms-survey",
  },
  {
    key: "Email Survey",
    name: "Email Survey",
    url: "https://fellafeeds.com/email-survey",
  },
  {
    key: "Customer Loyalty Management",
    name: "Customer Loyalty Management",
    url: "http://fellafeeds.com/customer-loyalty-management-system",
  },
  {
    key: "Customer Referral Program",
    name: "Customer Referral Program",
    url: "https://fellafeeds.com/customer-referral-program-software",
  },
];
export const TopbarIndustryMenu = [
  {
    url: "https://fellafeeds.com/restaurant-feedback-app",
    name: "Restaurants and cafes",
  },
  {
    url: "https://fellafeeds.com/customer-feedback-form-for-spas-and-salons",
    name: "Spas and Salons",
  },
  {
    url: "https://fellafeeds.com/hotel-guest-feedback-management-software",
    name: "Hotels",
  },
  {
    url: "https://fellafeeds.com/passenger-feedback-software-for-airport",
    name: "Airports",
  },
  {
    url: "https://fellafeeds.com/patient-feedback-system-for-hospitals",
    name: "Hospitals",
  },
  {
    url: "https://fellafeeds.com/customer-feedback-app-for-retail-stores-supermarkets-malls",
    name: "Retail Shops",
  },
  {
    url: "https://fellafeeds.com/automobile-customer-feedback-app",
    name: "Automobile Shops",
  },
  {
    url: "https://fellafeeds.com/bank-insurance-financial-survey-software",
    name: "Banks",
  },
  {
    url: "https://fellafeeds.com/restroom-management-feedback-system",
    name: "Washroom",
  },
  {
    url: "https://fellafeeds.com/student-classroom-education-feedback-system",
    name: "Education Institutes",
  },
];
