import Img1 from "../../assets/img/setting1.png";
import Img2 from "../../assets/img/setting2.png";
import Img3 from "../../assets/img/setting3.png";
import Img4 from "../../assets/img/setting4.png";

// Dashboard Type 

import CoporateImg from '../../assets/img/dashbordType/coporate.png';
import HospitalityImg from '../../assets/img/dashbordType/hospitality.png';
import VisitorImg from '../../assets/img/dashbordType/vistior.png';
import ServiceImg from '../../assets/img/dashbordType/service.png';


export const reportType = [
  {
    id: 1,
    title: "Overall Report",
    img: "form 1 form 2",
    subtitle: "Lorem ipsume the best pseudo text to be written in the text are",
  },
  {
    id: 2,
    title: "Perform Report",
    img: "form 1 form 2",
    subtitle: "Lorem ipsume the best pseudo text to be written in the text are",
  },
  // {
  //   id: 3,
  //   title: " Report",
  //   img: "form 1 form 2",
  //   subtitle: "Lorem ipsume the best pseudo text to be written in the text are",
  // },
  // {
  //   id: 4,
  //   title: " Report",
  //   img: "form 1 form 2",
  //   subtitle: "Lorem ipsume the best pseudo text to be written in the text are",
  // },
];
export const dashbordType = [
  { id: 1, title: "Corporate", img: CoporateImg },
  { id: 2, title: "Hospitality", img: HospitalityImg },
  { id: 3, title: " Visitor Dashboard", img:VisitorImg },
  { id: 4, title: " Service Center", img: ServiceImg },
  { id: 5, title: " Public FeedBack", img: VisitorImg },
];
export const data3 = [
  {
    id: 1,
    title: "Form Setting",
    img: Img1,
    subtitle: "Lorem ipsume the best pseudo text to be written in the text are",
  },
  {
    id: 2,
    title: "Text Setting",
    img: Img2,
    subtitle: "Lorem ipsume the best pseudo text to be written in the text are",
  },
  {
    id: 3,
    title: " Image Setting",
    img: Img3,
    subtitle: "Lorem ipsume the best pseudo text to be written in the text are",
  },
  {
    id: 4,
    title: " Device Setting",
    img: Img4,
    subtitle: "Lorem ipsume the best pseudo text to be written in the text are",
  },
];

export const brandData = [
  { id: 10, title: "Brand Image", key: "brand_img" },
  { id: 1, title: "Welcome Image", key: "welcome_img"},
  { id: 2, title: "Thankyou Image", key: "thankyou_img" },
];

export const feedbackData = [
  { id: 8, title: "FeedBack Banner Image", key:"open_feedback_mode_banner" },
  { id: 9, title: "Owner Image", key: "owner_image" },
];

export const ratingData = [
  { id: 3, title: "Star 1 Image", key: "rate_one" },
  { id: 4, title: "Star 2 Image", key: "rate_two" },
  { id: 5, title: "Star 3 Image", key: "rate_three" },
  { id: 6, title: "Star 4 Image", key: "rate_four" },
  { id: 7, title: "Star 5 Image", key: "rate_five" },
];


export const data = [
    {
      id: 1,
      title: "Date of Birth",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 2,
      title: "Date of Aniversary",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 3,
      title: "Bill Details",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 4,
      title: "Comment Below 3 Star",
      switch: true,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 5,
      title: "Compulsary Selfie",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 6,
      title: "Social Handles",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 7,
      title: "Skip Asking NSP",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 8,
      title: "Contact Details",
      switch: true,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 9,
      title: "Employee Section",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 10,
      title: "No Employee Mode",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 11,
      title: "Employee PIN",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 12,
      title: "Survey Mode",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 13,
      title: "Table No",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 14,
      title: "Bill No",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 15,
      title: "Amount",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 16,
      title: "Switch To Hindi",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 17,
      title: "Open Feedback Mode",
      switch: true,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 18,
      title: "Single Page Feedback",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 19,
      title: "Secure Setting",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 20,
      title: "Checklist Enable",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 21,
      title: "PIN Mode",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 22,
      title: "Launcher Mode",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 23,
      title: "Timer",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 24,
      title: "Name",
      switch: true,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 25,
      title: "Phone Number",
      switch: true,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 26,
      title: "Email Address",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 27,
      title: "Advertisement",
      switch: false,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
    {
      id: 28,
      title: "Mutiple Form",
      switch: true,
      para: "form 1 form 2",
      para1: "Form name where input are taken",
    },
  ];