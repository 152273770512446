import axios from "axios";

import {
  GET_ALL_TEXT,
  GET_ALL_DEVICE,
  GET_ALL_FORM,
  GET_ALL_IMAGES,
  GET_ALL_CARDS_FOR_TABLE,
  GET_ALL_CARDS_STATUS,
  GET_TOTAL_DEVICES,
} from "../constants/settings";

const SETTING_BASE_URL = `${process.env.REACT_APP_API_URL}/dashboard/apiv2`;

export const getAllTextMessage = () => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.get(
            `${SETTING_BASE_URL}/text_settings_api/`,
            { headers },
        )
        if (response.status === 200) {
          dispatch({ type: GET_ALL_TEXT, data: response.data.data })
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

export const updateAllTextMessage = (data) => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.post(
            `${SETTING_BASE_URL}/text_settings_api/`,
            data,
            { headers },
        )
        if (response.status === 200 || response.status === 201) {
          dispatch(getAllTextMessage())
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

// Device Settings

export const getAllDevice = () => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.get(
            `${SETTING_BASE_URL}/device_retrieve_api/`,
            { headers },
        )
        if (response.status === 200) {
          dispatch({ type: GET_ALL_DEVICE, data: response.data.data })
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

export const deleteDevice = (id) => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.delete(
            `${SETTING_BASE_URL}/device_delete_api/${id}`,
            { headers },
        )
        if (response.status === 200) {
          dispatch(getAllDevice())
          return response;
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

export const allowDevice = (id, data) => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.put(
            `${SETTING_BASE_URL}/device_delete_api/${id}`,
            data,
            { headers }
        )
        if (response.status === 200 || response.status === 201) {
          dispatch(getAllDevice())
          return response;
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

export const getTotalDevices = () => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.get(
            `${SETTING_BASE_URL}/total_device_count_api/`,
            { headers },
        )
        if (response.status === 200) {
          console.log(response)
          dispatch({ type: GET_TOTAL_DEVICES, data: response.data })
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

// Form Settings

export const allowFormDevice = (data) => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.post(
            `${SETTING_BASE_URL}/allow_form/`,
            data,
            { headers }
        )
        if (response.status === 200 || response.status === 201) {
          dispatch(getAllDevice())
          return response;
        }
    }
    catch (error) {
        throw (error)
    }
  }
}



export const getAllForm = () => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.get(
            `${SETTING_BASE_URL}/form_settings_api/`,
            { headers },
        )
        if (response.status === 200) {
          dispatch({ type: GET_ALL_FORM, data: response.data.data })
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

export const turnOnForm = (data) => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.post(
            `${SETTING_BASE_URL}/form_settings_api/`,
            data,
            { headers },
        )
        if (response.status === 200 || response.status === 201) {
          console.log("response text", response.data.data)
          dispatch(getAllForm())
        }
    }
    catch (error) {
        throw (error)
    }
  }
}


export const getAllImages = () => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.get(
            `${SETTING_BASE_URL}/image_settings_api/`,
            { headers },
        )
        if (response.status === 200) {
          console.log("response text", response.data.data)
          dispatch({ type: GET_ALL_IMAGES, data: response.data.data })
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

export const updateImage = (data) => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.post(
            `${SETTING_BASE_URL}/image_settings_api/`,
            data,
            { headers },
        )
        if (response.status === 200 || response.status === 201) {
          dispatch(getAllImages())
        }
    }
    catch (error) {
        throw (error)
    }
  }
}


// Get dahsboard card status 

export const getAllDashboardCardsStatus = () => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.get(
            `${SETTING_BASE_URL}/dashboard_toggle/`,
            { headers },
        )
        if (response.status === 200) {
          console.log("status", response.data.data)
          dispatch({ type: GET_ALL_CARDS_STATUS, data: response.data.data })
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

export const getAllDashboardCardsForTable = () => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.get(
            `${SETTING_BASE_URL}/dashboard_toggle_api/`,
            { headers },
        )
        if (response.status === 200) {
          console.log("status", response.data.data)
          dispatch({ type: GET_ALL_CARDS_FOR_TABLE, data: response.data.data })
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

export const toggleShowCards = (data) => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = `Token ${token}`
    try {
        let response = await axios.post(
            `${SETTING_BASE_URL}/dashboard_toggle_api/`,
            data,
            { headers },
        )
        if (response.status === 200 || response.status === 201) {
          dispatch(getAllDashboardCardsForTable())
        }
    }
    catch (error) {
        throw (error)
    }
  }
}

