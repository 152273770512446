import {
  GET_EMPLOYEE_LIST
} from '../constants/employee';

const initialState = {
  getEmployee: null,
}

export default function employee(state = initialState, action){
  switch(action.type){

    case GET_EMPLOYEE_LIST:
      return { ...state, getEmployee: action.data }

    default:
      return state;

  }
}