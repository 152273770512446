export const GET_TOTAL_FEEDBACKS = 'GET_TOTAL_FEEDBACKS'
export const GET_TOTAL_CUSTOMER = 'GET_TOTAL_CUSTOMER'
export const GET_TOTAL_LOYALITY = 'GET_TOTAL_LOYALITY'
export const GET_TOTAL_CAMPAIGN = 'GET_TOTAL_CAMPAIGN'
export const GET_TOTAL_REFFERALS = 'GET_TOTAL_REFFERALS'
export const GET_TOTAL_NEGATVIE_FEEDBACK = 'GET_TOTAL_NEGATVIE_FEEDBACK'
export const GET_UPCOMING_EVENTS = 'GET_UPCOMING_EVENTS'
export const GET_WEIGHTED_GRAPH = 'GET_WEIGHTED_GRAPH'
export const GET_NON_WEIGHTED_GRAPH = 'GET_NON_WEIGHTED_GRAPH'
export const GET_FEEDBACK_FORM_TYPE = 'GET_FEEDBACK_FORM_TYPE'
export const GET_SATISFACTION_SCORE = 'GET_SATISFACTION_SCORE'
export const GET_RETENTION_SCORE = 'GET_RETENTION_SCORE'