import axios from 'axios'
import {
    CREATE_FEEDBACK_FORM,
    ALL_FEEDBACK_FORM,
    FEEDBACK_FORM_DETAIL,
    ALL_QUESTIONS,
    ADD_QUESTION,
    EDIT_QUESTION_OPTION,
    SCREEN_INFO_SETTINGS,
    CUSTOMER_INFO_SETTINGS,
    BILL_INFO_SETTINGS,
    FEEDBACK_FORM_STATUS,
    PAGINATED_FEEDBACK_FORMS,
    FETCH_FEEDBACK_TAKERS,
    FETCH_FEEDBACK_AUTOMATION,
    FETCH_FEEDBACK_CUSTOMER_NOTIFICATION,
    DELETE_FEEDBACK_CUSTOMER_NOTIFICATION,
} from '../constants/feedbackForm'

const FEEDBACK_FORM_BASE_URL = `${process.env.REACT_APP_API_URL}/feedbacks/apiv2`

export const createFeedbackForm = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token
        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/feedback-forms/`,
                data,
                { headers },
            )
            if (response.status === 201) {
                dispatch({ type: CREATE_FEEDBACK_FORM, data: response.data.data })
                return response.data.data.id
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const feedbackFormDetail = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token
        try {
            const response = await axios.get(
                `${FEEDBACK_FORM_BASE_URL}/feedback-forms/?feedback_form_id=${data}`,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FEEDBACK_FORM_DETAIL, data: response.data.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const updateFeedbackFormDetail = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token
        try {
            const response = await axios.put(
                `${FEEDBACK_FORM_BASE_URL}/feedback-forms/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FEEDBACK_FORM_DETAIL, data: response.data.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const allFeedbackForm = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token
        try {
            const response = await axios.get(
                `${FEEDBACK_FORM_BASE_URL}/store/feedback-forms/`,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: ALL_FEEDBACK_FORM, data: response.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const allPaginatedFeedbackForm = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token
        try {
            const response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: PAGINATED_FEEDBACK_FORMS, data: response.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const feedbackFormStatus = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token
        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/store/feedback-forms/status/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FEEDBACK_FORM_STATUS, data: response.data.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const allQuestionsOfForm = form_id => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token
        try {
            const response = await axios.get(
                `${FEEDBACK_FORM_BASE_URL}/store/feedback-forms/questions/?form_id=${form_id}`,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: ALL_QUESTIONS, data: response.data.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const addQuestion = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token
        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/store/feedback-forms/questions/add/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: ADD_QUESTION, data: response.data.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const editQuestionData = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/store/feedback-forms/questions/update/question_option/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: EDIT_QUESTION_OPTION, data: response.data.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const updateOrderIndex = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/store/feedback-forms/questions/order/`,
                data,
                { headers }
            )
            if (response.status === 200) {
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const deleteOption = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/store/feedback-forms/questions/update/delete_option/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: EDIT_QUESTION_OPTION, data: response.data.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const deleteQuestion = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/store/feedback-forms/questions/update/delete_question/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FEEDBACK_FORM_DETAIL, data: response.data.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const screenInfoSettings = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.get(
                `${FEEDBACK_FORM_BASE_URL}/feedback-form/screen-info-settings/?form_id=${data}`,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: SCREEN_INFO_SETTINGS, data: response.data.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const screenInfoUpdate = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/feedback-form/screen-info-settings/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: SCREEN_INFO_SETTINGS, data: response.data.data })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const customerInfoSettings = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.get(
                `${FEEDBACK_FORM_BASE_URL}/feedback-form/customer-info-settings/?form_id=${data}`,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: CUSTOMER_INFO_SETTINGS, settings: response.data.settings, labels: response.data.labels })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const updateCustomerInfoSettings = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/feedback-form/customer-info-settings/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: CUSTOMER_INFO_SETTINGS, settings: response.data.settings, labels: response.data.labels })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const billInfoSettings = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.get(
                `${FEEDBACK_FORM_BASE_URL}/feedback-form/bill-info-settings/?form_id=${data}`,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: BILL_INFO_SETTINGS, settings: response.data.settings, labels: response.data.labels })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const updateBillInfoSettings = data => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/feedback-form/bill-info-settings/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: BILL_INFO_SETTINGS, settings: response.data.settings, labels: response.data.labels })
                return response.status
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchFeedbackTakers = () => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.get(
                `${FEEDBACK_FORM_BASE_URL}/feedback-taker/`,
                { headers },
            )
            if (response.status === 200) {
                return dispatch({ type: FETCH_FEEDBACK_TAKERS, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const createFeedbackQuestionAutomation = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/question-automation/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                // return dispatch({ type: FETCH_FEEDBACK_TAKERS, data: response.data.data })
                console.log(response)
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchFeedbackQuestionAutomation = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.get(
                `${FEEDBACK_FORM_BASE_URL}/question-automation/?form_id=${data}`,
                { headers },
            )
            if (response.status === 200) {
                return dispatch({ type: FETCH_FEEDBACK_AUTOMATION, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const updateCustomerNotification = (data, notificationType) => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.post(
                `${FEEDBACK_FORM_BASE_URL}/customer-notification/${notificationType}/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                return dispatch({ type: FETCH_FEEDBACK_CUSTOMER_NOTIFICATION, data: { email_template: response.data.email_template, sms_template: response.data.sms_template } })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchCustomerNotification = (data, notificationType) => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.get(
                `${FEEDBACK_FORM_BASE_URL}/customer-notification/${notificationType}/?form_id=${data}`,
                { headers },
            )
            if (response.status === 200) {
                return dispatch({
                    type: FETCH_FEEDBACK_CUSTOMER_NOTIFICATION,
                    notificationType,
                    data: {
                        email_template: response.data.email_template,
                        sms_template: response.data.sms_template
                    }
                })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const deleteCustomerNotification = (data, notificationType) => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Token ' + token

        try {
            const response = await axios.delete(
                `${FEEDBACK_FORM_BASE_URL}/customer-notification/${notificationType}/?form_id=${data}`,
                { headers },
            )
            if (response.status === 204) {
                dispatch({
                    type: DELETE_FEEDBACK_CUSTOMER_NOTIFICATION,
                    notificationType,
                })
                return true
            }
        }
        catch (error) {
            throw error
        }
    }
}
