import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const Preloader = ({ size, centered, color }) => {
    if (typeof (centered) === 'undefined' || centered === null) {
        centered = true
    }
    if (typeof (color) === 'undefined' || color === null) {
        color = ''
    }

    const loadingIcon = <LoadingOutlined
        style={{
            fontSize: size ? size : 64,
            color: color ? color : ''
        }}
        spin
    />

    return (
        <div style={{zIndex: 9999}} className={centered ? 'preloader' : ''}>
            <Spin indicator={loadingIcon} />
        </div>
    )
}

export default Preloader