import axios from 'axios'
import {
    FETCH_ZOMATO_ACCESS_TOKEN,
    // ZOMATO_SEARCH,
} from '../constants/zomato'

const ZOMATO_BASE_API_URL = `https://developers.zomato.com/api/v2.1`
const REVIEWS_API_URL = `${process.env.REACT_APP_API_URL}/reviews/apiv2`

export const fetchAccessToken = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${REVIEWS_API_URL}/zomato-api-key/`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_ZOMATO_ACCESS_TOKEN, data: response.data.key })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const search = (data) => async (dispatch, getState) => {
    const accessToken = getState().zomato.accessToken || data.zomatoAccessToken
    if (accessToken) {
        const headers = {
            'Content-Type': 'application.json',
            'user-key': accessToken
        }
        try {
            let response = await axios.get(
                `${ZOMATO_BASE_API_URL}/search?q=${data.query}`,
                { headers }
            )
            if (response.status === 200) {
                // dispatch({ type: ZOMATO_SEARCH, data: response.data })
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}