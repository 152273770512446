import {
  ADD_PRIMARY_MEMBER,
  COUPONS_MEMBERS_LIST,
  FETCH_ALL_MEMBERS,
  FETCH_COUPON_DETAILS,
  FETCH_MEMBERS_STAT,
  FETCH_PLAN_TYPE,
  FETCH_MEMBER_DETAILS,
  FETCH_DOCUMENTS,
  FETCH_SECONDARY_MEMBERS
} from "../constants/members";

const initialState = {
  membersData: null,
  planType: null,
  primaryMemberId: null,
  coupons: null,
  stat: {
    total_active_members: 0,
    total_inactive_members: 0,
    next_month_due: 0,
    sold_this_month: 0,
  },
  membersListForCoupons: null,
  memberDetail: null,
  documents:null,
  secondaryMembers:null
};

export default function members(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_MEMBERS:
      return { ...state, membersData: action.data };
    case FETCH_PLAN_TYPE:
      return { ...state, planType: action.data };

    case ADD_PRIMARY_MEMBER:
      return { ...state, primaryMemberId: action.data.id };

    case FETCH_COUPON_DETAILS:
      return { ...state, coupons: action.data };
    case FETCH_MEMBERS_STAT:
      return { ...state, stat: action.data };

    case COUPONS_MEMBERS_LIST:
      return { ...state, membersListForCoupons: action.data };
    // case for member detail
    case FETCH_MEMBER_DETAILS:
      return { ...state, memberDetail: action.data };

    case FETCH_DOCUMENTS:
      return { ...state, documents: action.data };
      case FETCH_SECONDARY_MEMBERS:
        console.log(action.data)
        return { ...state, secondaryMembers: action.data };

    default:
      return state;
  }
}
