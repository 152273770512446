import {
    FETCH_ZOMATO_ACCESS_TOKEN,
    // ZOMATO_SEARCH,
} from '../constants/zomato'


const initialState = {
    accessToken: null,
    // searchResult: null,
}

export default function zomato(state = initialState, action) {
    switch (action.type) {
        case FETCH_ZOMATO_ACCESS_TOKEN:
            return { ...state, accessToken: action.data }

        // case ZOMATO_SEARCH: {
        //     return { ...state, searchResult: action.data }
        // }
        default:
            return state
    }
}