import axios from 'axios'

import {
    FETCH_ALL_CUSTOMERS,
    FETCH_CUSTOMER_TAGS,
    FETCH_CUSTOMER_DETAILS,
    FETCH_CUSTOMER_FEEDBACKS,
    FETCH_CUSTOMER_TRANSACTIONS,
    FETCH_CUSTOMER_ANALYSIS,
    EDIT_CUSTOMER_TAG,
} from '../constants/customers'

const CUSTOMER_BASE_URL = `${process.env.REACT_APP_API_URL}/feedbacker/apiv2`

export const fetchCustomers = (data) => async (dispatch, getState) => {
    
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.get(
                `${CUSTOMER_BASE_URL}/customers/?${data}`,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_ALL_CUSTOMERS, data: response.data })
            }
        }
        catch (error) {
            throw (error)
        }
    }
}

export const fetchPaginatedCustomers = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.get(
                data.url,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_ALL_CUSTOMERS, data: response.data })
            }
        }
        catch (error) {
            throw (error)
        }
    }
}

export const fetchCustomerTags = () => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.get(
                `${CUSTOMER_BASE_URL}/customer-tags/`,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_CUSTOMER_TAGS, data: response.data.data })
            }
        }
        catch (error) {
            throw (error)
        }
    }
}

export const createCustomerTags = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.post(
                `${CUSTOMER_BASE_URL}/customer-tags/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_CUSTOMER_TAGS, data: response.data.data })
            }
        }
        catch (error) {
            throw (error)
        }
    }
}

export const editCustomerTags = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.put(
                `${CUSTOMER_BASE_URL}/customer-tags/`,
                data,
                { headers },
            )
            if (response.status === 200) {
                dispatch({ type: EDIT_CUSTOMER_TAG, data: response.data.data })
            }
        }
        catch (error) {
            throw (error)
        }
    }
}
export const customerImportBulk = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.post(
                `${CUSTOMER_BASE_URL}/import/bulk/`,
                data,
                { headers },
            )
            return response.data
        }
        catch (error) {
            throw (error)
        }
    }
}

export const updateCustomerDetails = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.put(
                `${CUSTOMER_BASE_URL}/customers-details/`,
                data,
                { headers },
            )
            return response.data
        }
        catch (error) {
            throw (error)
        }
    }
}


export const addANewCustomer = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.post(
                `${CUSTOMER_BASE_URL}/customers-details/`,
                data,
                { headers },
            )
            return response.data
        }
        catch (error) {
            throw (error)
        }
    }
}

export const fetchCustomerDetails = (customerID) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.get(
                `${CUSTOMER_BASE_URL}/customers-details/?customer_id=${customerID}`,
                { headers },
            )
            return dispatch({ type: FETCH_CUSTOMER_DETAILS, data: response.data.data })
        }
        catch (error) {
            throw (error)
        }
    }
}

export const fetchCustomerFeedbacks = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.get(
                `${CUSTOMER_BASE_URL}/customer/feedbacks/${data}`,
                { headers },
            )
            return dispatch({ type: FETCH_CUSTOMER_FEEDBACKS, data: response.data })
        }
        catch (error) {
            throw (error)
        }
    }
}

export const fetchCustomerTransactions = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.get(
                `${CUSTOMER_BASE_URL}/customer/transactions/${data}`,
                { headers },
            )
            return dispatch({ type: FETCH_CUSTOMER_TRANSACTIONS, data: response.data })
        }
        catch (error) {
            throw (error)
        }
    }
}

export const fetchCustomerAnalysis = () => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.get(
                `${CUSTOMER_BASE_URL}/customers/valid-email-analysis/`,
                { headers },
            )
            return dispatch({ type: FETCH_CUSTOMER_ANALYSIS, data: response.data.data })
        }
        catch (error) {
            throw (error)
        }
    }
}

export const forceValidateACustomer = (data) => async (dispatch, getState) => {
    let token = getState().auth.token
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
        headers['Authorization'] = `Token ${token}`
        try {
            let response = await axios.post(
                `${CUSTOMER_BASE_URL}/customers/force-validate/`,
                data,
                { headers },
            )
            return true
        }
        catch (error) {
            throw (error)
        }
    }
}

