import {
  GET_SUBSCRIBER
} from "../constants/subscription";

const initialState = {
  allSubscribers: null
}

export default function subscriptions( state = initialState, action ){
  switch(action.type){

    case GET_SUBSCRIBER:
      return { ...state, allSubscribers: action.data}

    default:
      return state;
  }
}