import { combineReducers } from 'redux'

import auth from './auth'
import feedbackForm from './feedbackForm'
import feedbackFormTemplates from './feedbackFormTemplates'
import response from './response'
import templates from './templates'
import customers from './customers'
import sms from './sms'
import emailCampaign from './emailCampaign'
import contentStudio from './contentStudio'
import balanceHistory from './balanceHistory'
import reports from './reports'
import feedbacks from './feedbacks'
import reviews from './reviews'
import zomato from './zomato'
import dashboard from './dashboard'
import employee from './employee'
import generateReport from './generateReport'
import settings from './settings'
import subscriptions from './subscriptions'
import members from './members'

export default combineReducers({
    auth,
    dashboard,
    employee,
    feedbackForm,
    feedbackFormTemplates,
    response,
    templates,
    customers,
    sms,
    emailCampaign,
    contentStudio,
    balanceHistory,
    reports,
    feedbacks,
    reviews,
    zomato,
    generateReport,
    settings,
    subscriptions,
    members
})
