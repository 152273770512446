import {
    FETCH_ALL_CUSTOMERS,
    FETCH_CUSTOMER_TAGS,
    FETCH_CUSTOMER_DETAILS,
    FETCH_CUSTOMER_FEEDBACKS,
    FETCH_CUSTOMER_TRANSACTIONS,
    FETCH_CUSTOMER_ANALYSIS,
    EDIT_CUSTOMER_TAG,
} from '../constants/customers'


const initialState = {
    customers: null,
    customerTags: null,
    customerDetails: null,
    customerFeedbacks: null,
    customerTransactions: null,
    customerAnalysis: null,
}

export default function customers(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_CUSTOMERS:
            return { ...state, customers: action.data }

        case FETCH_CUSTOMER_TAGS:
            return { ...state, customerTags: action.data }

        case FETCH_CUSTOMER_DETAILS:
            return { ...state, customerDetails: action.data }

        case FETCH_CUSTOMER_FEEDBACKS:
            return { ...state, customerFeedbacks: action.data }

        case FETCH_CUSTOMER_ANALYSIS:
            return { ...state, customerAnalysis: action.data }

        case FETCH_CUSTOMER_TRANSACTIONS:
            return { ...state, customerTransactions: action.data }

        case EDIT_CUSTOMER_TAG:
            return {
                ...state,
                customerTags: [action.data].concat(state.customerTags.filter(el => el.id !== action.data.id))
            }
        default:
            return state
    }
}