import axios from 'axios'
import {
    EMAIL_CAMPAIGN_LIST,
    EMAIL_CAMPAIGN_DETAIL,
    EMAIL_CAMPAIGN_BALANCE,
    FETCH_VERIFIED_EMAIL_ADDRESSES,
    FETCH_EMAIL_SETTINGS,
    DELETE_EMAIL_CAMPAIGN,
} from '../constants/emailCampaign'

const EMAIL_CAMPAIGN_BASE_URL = `${process.env.REACT_APP_API_URL}/mails/apiv2`

export const fetchEmailBalance = () => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.get(
                `${EMAIL_CAMPAIGN_BASE_URL}/balance/`,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: EMAIL_CAMPAIGN_BALANCE, data: response.data.data })
            }
        }

        catch (error) {
            throw error
        }
    }
}


export const fetchEmailCampaigns = () => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.get(
                `${EMAIL_CAMPAIGN_BASE_URL}/campaigns/list/`,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: EMAIL_CAMPAIGN_LIST, data: response.data })
            }
        }

        catch (error) {
            throw error
        }
    }
}

export const createEmailCampaign = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.post(
                `${EMAIL_CAMPAIGN_BASE_URL}/campaign/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                // return dispatch({ type: EMAIL_CAMPAIGN_LIST, data: response.data })
                return response.data
            }
        }

        catch (error) {
            throw error
        }
    }
}

export const updateEmailCampaign = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.put(
                `${EMAIL_CAMPAIGN_BASE_URL}/campaign/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: EMAIL_CAMPAIGN_DETAIL, data: response.data.data })
            }
        }

        catch (error) {
            throw error
        }
    }
}

export const deleteEmailCampaign = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.delete(
                `${EMAIL_CAMPAIGN_BASE_URL}/campaign/?email_campaign_id=${data}`,
                { headers },
            )

            if (response.status === 204) {
                return dispatch({ type: DELETE_EMAIL_CAMPAIGN, data: data })
            }
        }

        catch (error) {
            throw error
        }
    }
}

export const replicateEmailCampaign = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.post(
                `${EMAIL_CAMPAIGN_BASE_URL}/replicate/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: EMAIL_CAMPAIGN_LIST, data: response.data })
            }
        }

        catch (error) {
            throw error
        }
    }
}



export const fetchEmailCampaignDetail = (id) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.get(
                `${EMAIL_CAMPAIGN_BASE_URL}/campaign/?email_campaign_id=${id}`,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: EMAIL_CAMPAIGN_DETAIL, data: response.data.data })
            }
        }

        catch (error) {
            throw error
        }
    }
}

export const createEmailCampaignContent = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.post(
                `${EMAIL_CAMPAIGN_BASE_URL}/campaigns/content/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                return response.data.data
            }
        }

        catch (error) {
            throw error
        }
    }
}

export const updateEmailCampaignContent = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.put(
                `${EMAIL_CAMPAIGN_BASE_URL}/campaigns/content/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: EMAIL_CAMPAIGN_DETAIL, data: response.data.data })
            }
        }

        catch (error) {
            throw error
        }
    }
}

export const deleteEmailCampaignContent = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.delete(
                `${EMAIL_CAMPAIGN_BASE_URL}/campaigns/content/?${data}`,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: EMAIL_CAMPAIGN_DETAIL, data: response.data.data })
            }
        }

        catch (error) {
            throw error
        }
    }
}

export const addSocialFollowContent = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.post(
                `${EMAIL_CAMPAIGN_BASE_URL}/content/social-follow/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: EMAIL_CAMPAIGN_DETAIL, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const deleteSocialFollowContent = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.delete(
                `${EMAIL_CAMPAIGN_BASE_URL}/content/social-follow/?${data}`,
                { headers },
            )

            if (response.status === 200) {
                dispatch({ type: EMAIL_CAMPAIGN_DETAIL, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const updateOrderIndex = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.post(
                `${EMAIL_CAMPAIGN_BASE_URL}/content/update-index/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                return
            }
        }

        catch (error) {
            throw error
        }
    }
}



export const updateImageEmailContent = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.post(
                `${EMAIL_CAMPAIGN_BASE_URL}/content/update-image/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: EMAIL_CAMPAIGN_DETAIL, data: response.data.data })
            }
        }

        catch (error) {
            throw error
        }
    }
}

export const previewMail = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'text/html',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.get(
                `${EMAIL_CAMPAIGN_BASE_URL}/preview/?${data}`,
                { headers },
            )

            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const sendTestMail = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.post(
                `${EMAIL_CAMPAIGN_BASE_URL}/send-test/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const sendBulkMail = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.post(
                `${EMAIL_CAMPAIGN_BASE_URL}/send-mails/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const verifyEmailSES = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.post(
                `${EMAIL_CAMPAIGN_BASE_URL}/verify-email/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                return response.data
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchVerifiedEmailAddresses = () => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.get(
                `${EMAIL_CAMPAIGN_BASE_URL}/verified-emails/`,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: FETCH_VERIFIED_EMAIL_ADDRESSES, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchEmailSettings = () => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.get(
                `${EMAIL_CAMPAIGN_BASE_URL}/settings/`,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: FETCH_EMAIL_SETTINGS, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const updateEmailSettings = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.put(
                `${EMAIL_CAMPAIGN_BASE_URL}/settings/`,
                data,
                { headers },
            )

            if (response.status === 200) {
                return dispatch({ type: FETCH_EMAIL_SETTINGS, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}


// Schedule Email 

export const scheduleEmail = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }
        try {
            let response = await axios.post(
                `${EMAIL_CAMPAIGN_BASE_URL}/schedule_email/`,
                data,
                { headers },
            )

            if (response.status === 200) {
               console.log(response)
               return response
            }
        }
        catch (error) {
            throw error
        }
    }
}