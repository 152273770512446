export const FETCH_CREDENTIALS = 'FETCH_CREDENTIALS'
export const FETCH_CONNECTED_ACCOUNTS = 'FETCH_CONNECTED_ACCOUNTS'
export const FETCH_REVIEW_LIST = 'FETCH_REVIEW_LIST'

export const FETCH_ZOMATO_REVIEW_ANALYSIS = 'FETCH_ZOMATO_REVIEW_ANALYSIS'
export const PAGINATED_ZOMATO_REVIEWS = 'PAGINATED_ZOMATO_REVIEWS'
export const PAGINATED_ZOMATO_KEYPHRASES = 'PAGINATED_ZOMATO_KEYPHRASES'
export const FETCH_ZOMATO_KEYPHRASES_LIST = 'FETCH_ZOMATO_KEYPHRASES_LIST'

export const EMPTY_REVIEWS = 'EMPTY_REVIEWS'

export const FETCH_GOOGLE_REVIEW_LIST = 'FETCH_GOOGLE_REVIEW_LIST'
export const PAGINATED_GOOGLE_REVIEWS = 'PAGINATED_GOOGLE_REVIEWS'

export const FETCH_GOOGLE_REVIEW_ANALYSIS = 'FETCH_GOOGLE_REVIEW_ANALYSIS'
export const FETCH_GOOGLE_KEYPHRASES_LIST = 'FETCH_GOOGLE_KEYPHRASES_LIST'
export const PAGINATED_GOOGLE_KEYPHRASES = 'PAGINATED_GOOGLE_KEYPHRASES'

export const FETCH_FACEBOOK_REVIEW_LIST = 'FETCH_FACEBOOK_REVIEW_LIST'
export const PAGINATED_FACEBOOK_REVIEWS = 'PAGINATED_FACEBOOK_REVIEWS'

export const FETCH_FACEBOOK_REVIEW_ANALYSIS = 'FETCH_FACEBOOK_REVIEW_ANALYSIS'
export const FETCH_FACEBOOK_KEYPHRASES_LIST = 'FETCH_FACEBOOK_KEYPHRASES_LIST'
export const PAGINATED_FACEBOOK_KEYPHRASES = 'PAGINATED_FACEBOOK_KEYPHRASES'

export const FETCH_INSTAGRAM_REVIEW_LIST = 'FETCH_INSTAGRAM_REVIEW_LIST'
export const PAGINATED_INSTAGRAM_REVIEWS = 'PAGINATED_INSTAGRAM_REVIEWS'
export const FETCH_INSTAGRAM_REVIEW_ANALYSIS = 'FETCH_INSTAGRAM_REVIEW_ANALYSIS'
export const FETCH_INSTAGRAM_KEYPHRASES_LIST = 'FETCH_INSTAGRAM_KEYPHRASES_LIST'
export const PAGINATED_INSTAGRAM_KEYPHRASES = 'PAGINATED_INSTAGRAM_KEYPHRASES'

export const FETCH_TWITTER_MENTIONS_LIST = 'FETCH_TWITTER_MENTIONS_LIST'
export const PAGINATED_TWITTER_MENTIONS = 'PAGINATED_TWITTER_MENTIONS'

export const FETCH_TWITTER_REVIEW_ANALYSIS = 'FETCH_TWITTER_REVIEW_ANALYSIS'
export const FETCH_TWITTER_KEYPHRASES_LIST = 'FETCH_TWITTER_KEYPHRASES_LIST'
export const PAGINATED_TWITTER_KEYPHRASES = 'PAGINATED_TWITTER_KEYPHRASES'

export const FETCH_OVERALL_REPORT = 'FETCH_OVERALL_REPORT'