import {
    FETCH_CREDENTIALS,
    FETCH_CONNECTED_ACCOUNTS,
    FETCH_REVIEW_LIST,
    PAGINATED_ZOMATO_REVIEWS,
    FETCH_ZOMATO_REVIEW_ANALYSIS,
    FETCH_ZOMATO_KEYPHRASES_LIST,
    PAGINATED_ZOMATO_KEYPHRASES,

    EMPTY_REVIEWS,
    FETCH_GOOGLE_REVIEW_LIST,
    PAGINATED_GOOGLE_REVIEWS,
    FETCH_GOOGLE_REVIEW_ANALYSIS,
    FETCH_GOOGLE_KEYPHRASES_LIST,
    PAGINATED_GOOGLE_KEYPHRASES,

    FETCH_FACEBOOK_REVIEW_LIST,
    PAGINATED_FACEBOOK_REVIEWS,
    FETCH_FACEBOOK_REVIEW_ANALYSIS,
    FETCH_FACEBOOK_KEYPHRASES_LIST,
    PAGINATED_FACEBOOK_KEYPHRASES,

    FETCH_INSTAGRAM_REVIEW_LIST,
    PAGINATED_INSTAGRAM_REVIEWS,
    FETCH_INSTAGRAM_REVIEW_ANALYSIS,
    FETCH_INSTAGRAM_KEYPHRASES_LIST,
    PAGINATED_INSTAGRAM_KEYPHRASES,

    FETCH_TWITTER_MENTIONS_LIST,
    PAGINATED_TWITTER_MENTIONS,
    FETCH_TWITTER_REVIEW_ANALYSIS,
    FETCH_TWITTER_KEYPHRASES_LIST,
    PAGINATED_TWITTER_KEYPHRASES,

    FETCH_OVERALL_REPORT,
} from '../constants/reviews'

const initialState = {
    zomatoCredentials: null,
    accounts: null,

    reviewList: null,
    zomatoTopWords: null,
    zomatoAvgSentimentData: null,
    zomatoTopFollowers: null,
    zomatoKeyPhraseList: null,

    googleReviewList: null,
    googleTopWords: null,
    googleAvgSentimentData: null,
    googleKeyPhraseList: null,

    facebookReviewList: null,
    facebookTopWords: null,
    facebookAvgSentimentData: null,
    facebookKeyPhraseList: null,

    instagramReviewList: null,
    instagramTopWords: null,
    instagramAvgSentimentData: null,
    instagramKeyPhraseList: null,

    twitterMentionsList: null,
    twitterTopFollowers: null,
    twitterKeyPhraseList: null,
    twitterTopWords: null,
    twitterAvgSentimentData: null,

    sentimentData: null,
    keyphrasesData: null,
}

export default function reviews(state = initialState, action) {
    switch (action.type) {
        case FETCH_CREDENTIALS:
            return {
                ...state,
                zomatoCredentials: action.data.zomato,
            }
        case EMPTY_REVIEWS:
            return { ...state, reviewList: null, }

        case FETCH_CONNECTED_ACCOUNTS:
            return { ...state, accounts: action.data }

        // FACEBOOK

        case FETCH_FACEBOOK_REVIEW_LIST:
            return { ...state, facebookReviewList: action.data }

        case PAGINATED_FACEBOOK_REVIEWS:
            return {
                ...state,
                facebookReviewList:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.facebookReviewList.results, ...action.data.results]
                }
            }

        case FETCH_FACEBOOK_KEYPHRASES_LIST:
            return { ...state, facebookKeyPhraseList: action.data }

        case PAGINATED_FACEBOOK_KEYPHRASES:
            return {
                ...state,
                facebookKeyPhraseList:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.facebookKeyPhraseList.results, ...action.data.results]
                }
            }
        case FETCH_FACEBOOK_REVIEW_ANALYSIS:
            return {
                ...state,
                facebookTopWords: action.data.top_words,
                facebookAvgSentimentData: action.data.pie_data,
            }

        // INSTAGRAM
        case FETCH_INSTAGRAM_REVIEW_LIST:
            return { ...state, instagramReviewList: action.data }

        case PAGINATED_INSTAGRAM_REVIEWS:
            return {
                ...state,
                instagramReviewList:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.instagramReviewList.results, ...action.data.results]
                }
            }

        case FETCH_INSTAGRAM_KEYPHRASES_LIST:
            return { ...state, instagramKeyPhraseList: action.data }

        case PAGINATED_INSTAGRAM_KEYPHRASES:
            return {
                ...state,
                instagramKeyPhraseList:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.instagramKeyPhraseList.results, ...action.data.results]
                }
            }
        case FETCH_INSTAGRAM_REVIEW_ANALYSIS:
            return {
                ...state,
                instagramTopWords: action.data.top_words,
                instagramAvgSentimentData: action.data.pie_data,
            }
        // ZOMATO

        case FETCH_REVIEW_LIST:
            return { ...state, reviewList: action.data }

        case FETCH_ZOMATO_KEYPHRASES_LIST:
            return { ...state, zomatoKeyPhraseList: action.data }

        case PAGINATED_ZOMATO_REVIEWS:
            return {
                ...state,
                reviewList:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.reviewList.results, ...action.data.results]
                }
            }

        case PAGINATED_ZOMATO_KEYPHRASES:
            return {
                ...state,
                zomatoKeyPhraseList:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.zomatoKeyPhraseList.results, ...action.data.results]
                }
            }
        case FETCH_ZOMATO_REVIEW_ANALYSIS:
            return {
                ...state,
                zomatoTopWords: action.data.top_words,
                zomatoAvgSentimentData: action.data.pie_data,
                zomatoTopFollowers: action.data.top_followers,
            }

        // GOOGLE
        case FETCH_GOOGLE_REVIEW_LIST:
            return { ...state, googleReviewList: action.data }

        case PAGINATED_GOOGLE_REVIEWS:
            return {
                ...state,
                googleReviewList:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.googleReviewList.results, ...action.data.results]
                }
            }

        case FETCH_GOOGLE_KEYPHRASES_LIST:
            return { ...state, googleKeyPhraseList: action.data }

        case PAGINATED_GOOGLE_KEYPHRASES:
            return {
                ...state,
                googleKeyPhraseList:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.googleKeyPhraseList.results, ...action.data.results]
                }
            }
        case FETCH_GOOGLE_REVIEW_ANALYSIS:
            return {
                ...state,
                googleTopWords: action.data.top_words,
                googleAvgSentimentData: action.data.pie_data,
            }

        // TWITTER
        case FETCH_TWITTER_MENTIONS_LIST:
            return { ...state, twitterMentionsList: action.data }

        case FETCH_TWITTER_KEYPHRASES_LIST:
            return { ...state, twitterKeyPhraseList: action.data }

        case FETCH_TWITTER_REVIEW_ANALYSIS:
            return {
                ...state,
                twitterTopWords: action.data.top_words,
                twitterAvgSentimentData: action.data.pie_data,
                twitterTopFollowers: action.data.top_followers,
            }

        case PAGINATED_TWITTER_MENTIONS:
            return {
                ...state,
                twitterMentionsList:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.twitterMentionsList.results, ...action.data.results]
                }
            }

        case PAGINATED_TWITTER_KEYPHRASES:
            return {
                ...state,
                twitterKeyPhraseList:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.twitterKeyPhraseList.results, ...action.data.results]
                }
            }

        case FETCH_OVERALL_REPORT:
            return { ...state, sentimentData: action.data.sentiment_data, keyphrasesData: action.data.keyphrases_data }

        default:
            return state
    }
}