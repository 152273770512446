import React, { useState } from "react";
import { Link , useParams, useHistory} from "react-router-dom";
import { Row, Col, Form, Input, Button, message } from "antd";
import logo from "../../assets/img/ff_logo.png";
import logoBlack from "../../assets/img/ff_black_logo.png";
import GlobalLayout from "../generics/layout";
import styles from "./login.module.scss";
import { resetPassword } from "../../actions/auth";

export default function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const params = useParams()
  const history = useHistory()

  const handleLogin = (values) => {
    let data = {...values}
    data.token = params.token;

    setLoading(true);
    resetPassword(data).then((res) => {
        setLoading(false)
        if(res.status == 'success'){
            message.success(res.message)
            history.push('/login')
        } else {
            message.error(res.message)
        }
    });
  };

  return (
    <GlobalLayout title="Log in" sidebar={false} topbar={false} display="block">
      <div className={styles.container}>
        <Row className={styles.row}>
          <Col sm={24} md={14}>
            <div className={`${styles.flex} ${styles.bannerContainer}`}>
              <img src={logo} alt="FellaFeeds" className={styles.bannerLogo} />
              <p>"There is no failure. Only Feedback."</p>
            </div>
          </Col>
          <Col
            sm={24}
            md={10}
            style={{ position: "relative", overflow: "hidden" }}
          >
            <div className={styles.signUpLink}>
              <p>
                Don't have an account yet?{" "}
                <Button>
                  <Link to="/register/">Sign up</Link>
                </Button>
              </p>
            </div>
            <div className={`${styles.flex} ${styles.loginContainer}`}>
              <div className={styles.loginBox}>
                <img
                  className={styles.title}
                  src={logoBlack}
                  alt="Fellafeeds"
                />
                <span className={styles.subtitle}>Reset password</span>
                <Form layout="vertical" onFinish={handleLogin}>
                  <Form.Item
                    name="new_password"
                    label="New password"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                  <Form.Item
                    name="confirm_password"
                    label="Confirm password"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      loading={loading}
                      block
                      htmlType="submit"
                      type="primary"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </GlobalLayout>
  );
}
