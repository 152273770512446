import {
    LOADING,
    LOADED,
    USER_LOADING,
    USER_LOADED,
    LOGIN_SUCCESSFUL,
    REGISTRATION_SUCCESSFUL,
    AUTHENTICATION_ERROR,
    LOGIN_FAILED,
    REGISTRATION_FAILED,
    LOGOUT_SUCCESSFUL,
    FETCH_STORE_CONFIG,
    USER_LOGIN_SUCCESS
} from "../constants/auth";

const initialState = {
    loading: false,
    token: null,
    isAuthenticated: null,
    isLoading: false,
    user: null,
    errors: {},
    storeConfig: null,
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case LOADING:
            return { ...state, loading: true }

        case LOADED:
            return { ...state, loading: false }

        case USER_LOADING:
            return { ...state, isLoading: true }

        case USER_LOADED:
            return { ...state, isLoading: false, user: action.data, isAuthenticated: true, }

        case LOGIN_SUCCESSFUL:
        case REGISTRATION_SUCCESSFUL:
            localStorage.setItem('Token', action.data.token)
            return { ...state, ...action.data, isAuthenticated: true, isLoading: false, error: null }

        case AUTHENTICATION_ERROR:
        case LOGIN_FAILED:
        case REGISTRATION_FAILED:
        case LOGOUT_SUCCESSFUL:
            localStorage.removeItem('Token')
            localStorage.removeItem('user')
            localStorage.removeItem('TO')
            return { ...state, errors: action.data, token: null, user: null, isAuthenticated: false, isLoading: false }

        case USER_LOGIN_SUCCESS:
            return { ...state, token: action.data, isAuthenticated: true, user: action.user }

        case FETCH_STORE_CONFIG:
            return { ...state, storeConfig: action.data.store }

        default:
            return state;
    }
}