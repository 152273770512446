import axios from 'axios';  // for request 

// Local Imports

import {
  GET_EMPLOYEE_LIST
} from '../constants/employee';

let headers = { 'Content-Type': 'application/json' } // headers 

export const fetchEmployee = () => async (dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/feedbacktaker/apiv2/feedback-taker/`,
        { headers }
      )
      
      if(response.status === 200){
        console.log("emplyee", response.data)
        dispatch({ type: GET_EMPLOYEE_LIST, data: response.data.data })
      }

    } catch(err) {
      throw err
    }
  }
}

export const addNewEmployee = (data) => async(dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}/feedbacktaker/apiv2/feedback-taker/`,
        data,
        { headers }
      )
      
      if(response.status === 200){
        console.log("emplyee", response.data)
        dispatch(fetchEmployee())
      }

    } catch(err) {
      throw err
    }
  }
}


export const updateEmployee = (data, id) => async(dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_URL}/feedbacktaker/apiv2/feedback-taker/${id}`,
        data,
        { headers }
      )
      
      if(response.status === 200){
        dispatch(fetchEmployee())
      }

    } catch(err) {
      throw err
    }
  }
}
