import axios from 'axios'
import {
    USER_LOADING,
    USER_LOGIN_SUCCESS,
    USER_LOADED,
    REGISTRATION_FAILED,
    FETCH_STORE_CONFIG,
    LOGOUT_SUCCESSFUL,
} from '../constants/auth'


export const loginUserSuccess = (token, user) => async (dispatch) => {
    localStorage.setItem('Token', token)
    localStorage.setItem('user', JSON.stringify(user))
    dispatch({ type: USER_LOGIN_SUCCESS, data: token, user: user })
}

export const loadUser = () => async (dispatch, getState) => {
    const token = getState().auth.token

    let headers = {
        'Content-Type': 'application/json',
    }

    if (token) {
        dispatch({ type: USER_LOADING })
        headers['Authorization'] = 'Token ' + token
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/rest-auth/user/`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: USER_LOADED, data: response.data })
                return response.status
            }
        }
        catch (error) {
            // dispatch({ type: AUTHENTICATION_ERROR, data: error })
            throw error.message
        }
    }
}

export const register = (data) => async (dispatch) => {
    try {
        let response = await axios.post(
            `${process.env.REACT_APP_API_URL}/rest-auth/registration/`,
            data
        )
        if (response.status === 201) {
            dispatch(loginUserSuccess(response.data.key, response.data.user))
        }
    }
    catch (error) {
        dispatch({ type: REGISTRATION_FAILED, data: error.response.data })
        throw error.message
    }
}

export const login = (data) => async (dispatch) => {
    try {
        let response = await axios.post(
            `${process.env.REACT_APP_API_URL}/rest-auth/login/`,
            data
        )
        if (response.status === 200) {
            dispatch(loginUserSuccess(response.data.key, response.data.user))
        }
    }
    catch (error) {
        dispatch({ type: REGISTRATION_FAILED, data: error.response.data })
        throw error.message
    }
}

export const forgotPassword = async (data) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/api/forgot-password/`,
        data
      );
      if (res.status == 200) {
          return res.data
      }
    } catch (error) {
      throw error.message;
    }
  
};

export const resetPassword = async (data) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/api/change-password/`,
        data
      );
      if (res.status == 200) {
          return res.data
      }
    } catch (error) {
      throw error.message;
    }
  
};


export const logout = () => async (dispatch) => {
    dispatch({ type: LOGOUT_SUCCESSFUL })
}

export const fetchStoreConfig = () => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            }

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/company/apiv2/store-config/`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_STORE_CONFIG, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}