import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import GlobalLayout from "../generics/layout";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

// @antd

import { message } from "antd";

// @react-router-dom

import { Link } from "react-router-dom";

// @redux 

import { connect } from "react-redux";
import { settings } from "../../actions";

import "./styles/index.scss"

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 4,
    "&$checked": {
      transform: "translateX(24px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#219653",
        opacity: 1,
        border: "none",
      },
    },

  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
    

  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: "black",
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "100%",
    padding: 24,
    position: "relative"
  },
  tableRow: {
    background: "#18283B",
    color: "white",
  },

}));


function FormSetting({getAllForm, allForm, turnOnForm}) {
  const classes = useStyles();
  const [check, setCheck] = useState(false);
  const [alert, setAlert] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllForm()
  },[])

  useEffect(() => {
  
    if(selectedForm){
      let key = selectedForm.title;
      const data = {};
      data[`${key}`] = selectedForm.bool_value === true ? false : true;
      turnOnForm(data)
        .then(res => {
          message.success(`${key} updated successfully.`);
        })
        .catch(err => {
          message.error("something went wrong.")
        })
      console.log("data", data) 
      // console.log("back",form)
    }
  },[check])

  const handleChange = (form) => {
    setCheck(!check)
    setSelectedForm(form)
  }

  return (
    <GlobalLayout>
      <div className="device__container">
        <p className="device__title">
          <span><Link to="/settings">Settings</Link> {'>'} <b>Form Setting</b></span>
          <br/>
          Form Setting
        </p>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableRow} align="left">Sr. No.</TableCell>
                <TableCell className={classes.tableRow} align="left">Title</TableCell>
                <TableCell className={classes.tableRow} align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allForm && allForm.map((item) => {
                return(
                  <>
                    {
                      item && item.map((row, index) => (
                        <TableRow key={row.pin}>
                          <TableCell align="left" style={{textTransform: "capitalize"}}>{index + 1}</TableCell>
                          <TableCell align="left" style={{textTransform: "capitalize"}}>{row.title}</TableCell>
                          <TableCell align="right">
                            <AntSwitch
                              // checked={check}
                              defaultChecked={row.bool_value}
                              onChange={() => handleChange(row)}
                              name="checkedC"
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </GlobalLayout>
  );
}

const mapStateToProps = state => ({
  allForm: state.settings.allForm,
})

const mapDispatchToProps = dispatch => ({
  getAllForm: () => dispatch(settings.getAllForm()),
  turnOnForm: (data) => dispatch(settings.turnOnForm(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(FormSetting);
