import {
  GET_TOTAL_FEEDBACKS,
  GET_TOTAL_CUSTOMER,
  GET_TOTAL_LOYALITY,
  GET_TOTAL_CAMPAIGN,
  GET_TOTAL_REFFERALS,
  GET_TOTAL_NEGATVIE_FEEDBACK,
  GET_UPCOMING_EVENTS,
  GET_WEIGHTED_GRAPH,
  GET_NON_WEIGHTED_GRAPH,
  GET_FEEDBACK_FORM_TYPE,
  GET_SATISFACTION_SCORE,
  GET_RETENTION_SCORE,
} from '../constants/dashboard'

const initialState = {
  totalFeedbacks: null,
  totalCustomers: null,
  totalLoyality: null,
  totalCampaign: null,
  totalRefferal: null,
  totalNegatvieFeedback: null,
  totalUpcomingEvents: null,
  weightedGraph: null,
  nonWeightGraph: null,
  feedbackFormType: null,
  satisfactionScoreChart: null,
  retentionScoreChart: null,
}

export default function dashboard(state = initialState, action) {
  switch(action.type){
    case GET_TOTAL_FEEDBACKS:
      return { ...state, totalFeedbacks: action.data }

    case GET_TOTAL_CUSTOMER:
      return { ...state, totalCustomers: action.data }
    
    case GET_TOTAL_LOYALITY:
      return { ...state, totalLoyality: action.data }

    case GET_TOTAL_CAMPAIGN:
      return { ...state, totalCampaign: action.data }

    case GET_TOTAL_REFFERALS:
      return { ...state, totalRefferal: action.data }

    case GET_TOTAL_NEGATVIE_FEEDBACK:
      return { ...state, totalNegatvieFeedback: action.data }
    
    case GET_UPCOMING_EVENTS:
      return { ...state, totalUpcomingEvents: action.data }

    case GET_WEIGHTED_GRAPH:
      return { ...state, weightedGraph: action.data }

    case GET_NON_WEIGHTED_GRAPH:
      return { ...state, nonWeightGraph: action.data }

    case GET_FEEDBACK_FORM_TYPE:
      return { ...state, feedbackFormType: action.data }

    case GET_SATISFACTION_SCORE:
      return { ...state, satisfactionScoreChart: action.data }
      
    case GET_RETENTION_SCORE:
      return { ...state, retentionScoreChart: action.data }

    default: 
      return state
      
  }
}