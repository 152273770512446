import {
    SMS_BALANCE_HISTORY,
    EMAIL_BALANCE_HISTORY,
    FETCH_ALL_BALANCE_DATA,
} from '../constants/balanceHistory.js'


const initialState = {
    smsBalanceHistory: null,
    emailBalanceHistory: null,
    allBalanceData: null,
}

export default function balanceHistory(state = initialState, action) {
    switch (action.type) {
        case SMS_BALANCE_HISTORY:
            return { ...state, smsBalanceHistory: action.data }

        case EMAIL_BALANCE_HISTORY:
            return { ...state, emailBalanceHistory: action.data }

        case FETCH_ALL_BALANCE_DATA:
            return { ...state, allBalanceData: action.data }

        default:
            return state
    }
}