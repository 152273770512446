import axios from 'axios'

import {
    ALL_TEMPLATES,
    CATEGORY_TEMPLATES,
    GET_TEMPLATE_DETAIL,
    FORM_CREATED_VIA_TEMPLATE,
    FORM_VIA_TEMPLATE_AFTER_AUTH,
    SIMILAR_CATEGORY_TEMPLATES,
} from '../constants/templates'

const TEMPLATE_BASE_URL = `${process.env.REACT_APP_API_URL}/feedbacks/apiv2/template`


export const getAllFeedbackFormTemplates = () => async (dispatch, getState) => {
    let headers = {
        'Content-Type': 'application/json',
    }
    try {
        let response = await axios.get(
            `${process.env.REACT_APP_API_URL}/feedbacks/apiv2/feedback-form/template-form/`,
            { headers }
        )

        if (response.status === 200) {
            dispatch({ type: ALL_TEMPLATES, data: response.data.data })
        }
    }
    catch (error) {
        throw error
    }
}

export const getTemplatesOfACategory = (data) => async (dispatch, getState) => {
    // dispatch({ type: TEMPLATES_NULL })
    let headers = {
        'Content-Type': 'application/json',
    }

    try {
        let response = await axios.get(
            `${TEMPLATE_BASE_URL}/feedback-form/category/?slug=${data}`,
            { headers }
        )

        if (response.status === 200) {
            dispatch({ type: CATEGORY_TEMPLATES, data: response.data.data })
        }
    }
    catch (error) {
        throw error
    }

}


export const getSimilarTemplatesOfACategory = (data) => async (dispatch, getState) => {
    // dispatch({ type: TEMPLATES_NULL })
    let headers = {
        'Content-Type': 'application/json',
    }
    try {
        let response = await axios.get(
            `${TEMPLATE_BASE_URL}/feedback-form/category/similar/?slug=${data}`,
            { headers }
        )

        if (response.status === 200) {
            dispatch({ type: SIMILAR_CATEGORY_TEMPLATES, data: response.data.data })
        }
    }
    catch (error) {
        throw error
    }

}

export const getTemplateFeedbackForm = data => async dispatch => {
    let headers = {
        'Content-Type': 'application/json'
    }

    try {
        let response = await axios.get(
            `${TEMPLATE_BASE_URL}/detail/?template_form_id=${data}`,
            { headers }
        )

        if (response.status === 200) {
            return dispatch({ type: GET_TEMPLATE_DETAIL, data: response.data.data })
        }
    }
    catch (error) {
        throw error
    }
}

export const createFormFromTemplate = (data) => async (dispatch, getState) => {
    const { token } = getState().auth

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        let response = await axios.post(
            `${TEMPLATE_BASE_URL}/create/feedback-form/`,
            data,
            { headers }
        )
        if (response.status === 200) {
            dispatch({ type: FORM_CREATED_VIA_TEMPLATE, data: response.data.data })
        }
    }
}

export const createFormFromTemplateAfterAuth = (data) => async (dispatch) => {
    dispatch({ type: FORM_VIA_TEMPLATE_AFTER_AUTH, data: data })
}