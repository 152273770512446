import {
    CREATE_FEEDBACK_FORM,
    ALL_FEEDBACK_FORM,
    FEEDBACK_FORM_DETAIL,
    ALL_QUESTIONS,
    ADD_QUESTION,
    EDIT_QUESTION_OPTION,
    UPDATED_FEEDBACK_FORM,
    SCREEN_INFO_SETTINGS,
    CUSTOMER_INFO_SETTINGS,
    BILL_INFO_SETTINGS,
    FEEDBACK_FORM_STATUS,
    PAGINATED_FEEDBACK_FORMS,
    FETCH_FEEDBACK_TAKERS,
    FETCH_FEEDBACK_AUTOMATION,
    FETCH_FEEDBACK_CUSTOMER_NOTIFICATION,
    DELETE_FEEDBACK_CUSTOMER_NOTIFICATION,
} from '../constants/feedbackForm'

const initialState = {
    feedbackForm: null,
    allFeedbackForms: null,
    allQuestions: null,
    screenInfo: null,
    customerInfo: null,
    customerInfoLabels: null,
    billInfo: null,
    billInfoLabels: null,
    feedbackTakers: null,
    feedbackQuestionAutomation: null,
    emailCustomerNotification13: null,
    emailCustomerNotification45: null,
    smsCustomerNotification13: null,
    smsCustomerNotification45: null,
}

export default function feedbackForm(state = initialState, action) {
    switch (action.type) {
        case CREATE_FEEDBACK_FORM:
            return { ...state, feedbackForm: action.data, allQuestions: action.data.all_questions }

        case ALL_FEEDBACK_FORM:
            return { ...state, allFeedbackForms: action.data }

        case PAGINATED_FEEDBACK_FORMS:
            return {
                ...state,
                allFeedbackForms:
                {
                    prev: action.data.prev,
                    next: action.data.next,
                    results: [...state.allFeedbackForms.results, ...action.data.results]
                }
            }

        case FEEDBACK_FORM_STATUS:
            const index = state.allFeedbackForms.results.findIndex(el => el.id === action.data.id)
            return {
                ...state,
                allFeedbackForms: {
                    ...state.allFeedbackForms,
                    results: [
                        ...state.allFeedbackForms.results.slice(0, index),
                        Object.assign({}, state.allFeedbackForms.results[index], action.data),
                        ...state.allFeedbackForms.results.slice(index + 1)
                    ]
                }
            }

        case FEEDBACK_FORM_DETAIL:
            return {
                ...state,
                feedbackForm: action.data,
                allQuestions: action.data.all_questions,
                customerInfo: action.data.customer_info_settings,
                customerInfoLabels: action.data.customer_info_labels,
                billInfo: action.data.bill_info_settings,
                billInfoLabels: action.data.bill_info_labels,
            }

        case ALL_QUESTIONS:
            return { ...state, allQuestions: action.data }

        case ADD_QUESTION:
            return { ...state, allQuestions: action.data }

        case EDIT_QUESTION_OPTION:
            return { ...state, allQuestions: action.data }

        case UPDATED_FEEDBACK_FORM:
            return { ...state }

        case SCREEN_INFO_SETTINGS:
            return { ...state, screenInfo: action.data }

        case CUSTOMER_INFO_SETTINGS:
            return { ...state, customerInfo: action.settings, customerInfoLabels: action.labels }

        case BILL_INFO_SETTINGS:
            return { ...state, billInfo: action.settings, billInfoLabels: action.labels }

        case FETCH_FEEDBACK_TAKERS:
            return { ...state, feedbackTakers: action.data }

        case FETCH_FEEDBACK_AUTOMATION:
            return { ...state, feedbackQuestionAutomation: action.data }

        case FETCH_FEEDBACK_CUSTOMER_NOTIFICATION:
            if (action.notificationType === '13') {
                return {
                    ...state,
                    emailCustomerNotification13: action.data.email_template,
                    smsCustomerNotification13: action.data.sms_template,
                }
            }
            if (action.notificationType === '45') {
                return {
                    ...state,
                    emailCustomerNotification45: action.data.email_template,
                    smsCustomerNotification45: action.data.sms_template,
                }
            }
            else {
                return { ...state }
            }
        case DELETE_FEEDBACK_CUSTOMER_NOTIFICATION:
            if (action.notificationType === '13') {
                return {
                    ...state,
                    emailCustomerNotification13: null,
                    smsCustomerNotification13: null,
                }
            }
            if (action.notificationType === '45') {
                return {
                    ...state,
                    emailCustomerNotification45: null,
                    smsCustomerNotification45: null,
                }
            }
            else {
                return { ...state }
            }

        default:
            return state
    }
}
