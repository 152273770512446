import React from "react";
import { Layout } from "antd";
import SEO from "./seo.js";
import SideBar from "../sidebar";
import TopBar from "../topbar";

const GlobalLayout = (props) => {
  // const { Content } = Layout
  let sidebar = props.sidebar;
  let topbar = props.topbar;

  const environment = process.env.NODE_ENV;
  const production = environment === "production";

  if (sidebar === undefined) {
    sidebar = true;
  }
  if (topbar === undefined) {
    topbar = true;
  }

  return (
    <Layout>
      {production && (
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){
                          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                      })(window, document, "clarity", "script", "fgefddj508");`,
          }}
        ></script>
      )}
      <SEO title={props.title} description={props.description} />
      {topbar ? <TopBar headerNotFixed={props.headerNotFixed} /> : ""}
      <Layout>
        {sidebar ? <SideBar /> : ""}
        {topbar && !props.headerNotFixed ? (
          <div
            style={{
              display: `${
                props.display === "flex" || props.display === undefined
                  ? "flex"
                  : props.display
              }`,
              width: "100%",
              marginTop: "4rem",
              minHeight: `${props.minHeight ? props.minHeight : "100vh"}`,
              
            }}
          >
            {props.children}
          </div>
        ) : (
          <div
            style={{
              display: `${
                props.display === "flex" || props.display === undefined
                  ? "flex"
                  : props.display
              }`,
              width: "100%",
              margin: 0,
              minHeight: `${props.minHeight ? props.minHeight : "100vh"}`,
              maxHeight: `${props.maxHeight ? props.maxHeight : ""}`,
            }}
          >
            {props.children}
           
          </div>
        )}
      </Layout>
    </Layout>
  );
};

export default GlobalLayout;
