import { verifiedEmailAddresses } from '../actions/emailCampaign'
import {
    EMAIL_CAMPAIGN_LIST,
    EMAIL_CAMPAIGN_DETAIL,
    EMAIL_CAMPAIGN_BALANCE,
    FETCH_VERIFIED_EMAIL_ADDRESSES,
    FETCH_EMAIL_SETTINGS,
    DELETE_EMAIL_CAMPAIGN,
} from '../constants/emailCampaign'

const initialState = {
    emailCampaigns: null,
    emailCampaignDetail: null,
    emailBalance: null,
    verifiedEmailAddresses: null,
    emailSettings: null,
}

export default function emailCampaign(state = initialState, action) {
    switch (action.type) {
        case EMAIL_CAMPAIGN_LIST:
            return { ...state, emailCampaigns: action.data }

        // case EDIT_

        case DELETE_EMAIL_CAMPAIGN:
            const newEmailCampaigns = state.emailCampaigns.filter(el => el.id !== action.data)
            
            return {
                ...state,
                emailCampaigns: newEmailCampaigns
            }

        case EMAIL_CAMPAIGN_DETAIL:
            return { ...state, emailCampaignDetail: action.data }

        case EMAIL_CAMPAIGN_BALANCE:
            return { ...state, emailBalance: action.data }

        case FETCH_VERIFIED_EMAIL_ADDRESSES:
            return { ...state, verifiedEmailAddresses: action.data }

        case FETCH_EMAIL_SETTINGS:
            return { ...state, emailSettings: action.data }

        default:
            return state
    }
}