import axios from 'axios'

import {
    FETCH_EMAIL_REPORTS_LIST,
    FETCH_EMAIL_REPORT,
    FETCH_EMAIL_REPORT_DETAILS,
    FETCH_PAGINATED_EMAIL_REPORT_DETAILS,
    FETCH_EMAIL_SUMMARY,
    FETCH_PAGINATED_EMAIL_REPORTS_LIST,
    FETCH_SCHEDULED_EMAILS,
} from '../constants/reports'

const EMAIL_API_URL = `${process.env.REACT_APP_API_URL}/mails/apiv2`

export const fetchEmailReportsList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${EMAIL_API_URL}/report-list/`,
                { headers }
            )

            if (response.status === 200) {
                dispatch({ type: FETCH_EMAIL_REPORTS_LIST, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedEmailReportsList = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                data.url,
                { headers }
            )

            if (response.status === 200) {
                dispatch({ type: FETCH_PAGINATED_EMAIL_REPORTS_LIST, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchEmailSingleReport = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${EMAIL_API_URL}/report/?report_id=${data}`,
                { headers }
            )

            if (response.status === 200) {
                dispatch({ type: FETCH_EMAIL_REPORT, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchEmailReportDetails = (id) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${EMAIL_API_URL}/report/detail/?id=${id}`,
                { headers }
            )

            if (response.status === 200) {
                dispatch({ type: FETCH_EMAIL_REPORT_DETAILS, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchFilteredEmailReportDetails = (id, filterQuery) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${EMAIL_API_URL}/report/detail/?id=${id}&${filterQuery}`,
                { headers }
            )

            if (response.status === 200) {
                dispatch({ type: FETCH_EMAIL_REPORT_DETAILS, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchPaginatedReportDetails = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                data.url,
                { headers }
            )

            if (response.status === 200) {
                dispatch({ type: FETCH_PAGINATED_EMAIL_REPORT_DETAILS, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const stopEmailCampaign = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.post(
                `${EMAIL_API_URL}/stop-campaign/`,
                data,
                { headers }
            )

            if (response.status === 200) {
                dispatch(fetchEmailReportsList())
                return true
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchReportInXLS = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.post(
                `${EMAIL_API_URL}/report-in-xls/`,
                data,
                { headers }
            )

            if (response.status === 200) {
                // dispatch(fetchEmailReports())
                return response
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchLinkSummary = (data) => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${EMAIL_API_URL}/report/link-summary/?report_id=${data}`,
                { headers }
            )

            if (response.status === 200) {
                dispatch({ type: FETCH_EMAIL_SUMMARY, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchScheduledEmails = () => async (dispatch, getState) => {
    const token = getState().auth.token

    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${EMAIL_API_URL}/email_schedule/`,
                { headers }
            )

            if (response.status === 200) {
                dispatch({ type: FETCH_SCHEDULED_EMAILS, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}


