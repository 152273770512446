import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import GlobalLayout from "../generics/layout";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

// @antd

import { message } from "antd";

// @react-router-dom

import { Link } from "react-router-dom";

// @redux 

import { connect } from "react-redux";
import { settings } from "../../actions";

import "./styles/index.scss"

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 4,
    "&$checked": {
      transform: "translateX(24px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#219653",
        opacity: 1,
        border: "none",
      },
    },

  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
    

  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: "black",
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "100%",
    padding: 24,
    position: "relative"
  },
  tableRow: {
    background: "#18283B",
    color: "white",
  },

}));


function DashboardSetting({
  getAllDashboardCardsForTable, 
  cardsForTable, 
  toggleShowCards,
}) {
  const classes = useStyles();
  const [check, setCheck] = useState(false);
  const [alert, setAlert] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllDashboardCardsForTable()
  },[])

  useEffect(() => {
  
    if(selectedForm){
      const data = {};
      data[`show${selectedIndex}`] = selectedForm.show === true ? false : true;
      // data[`title${selectedIndex}`] = selectedForm.title;
      data[`name${selectedIndex}`] = selectedForm.name;
      toggleShowCards(data)
        .then(res => {
          message.success(`${selectedForm.title} updated successfully.`);
        })
        .catch(err => {
          message.error("something went wrong.")
        })
    }
  },[check])

  const handleChange = (form, index) => {
    setCheck(!check)
    setSelectedForm(form)
    setSelectedIndex(index)
  }

  return (
    <GlobalLayout>
      <div className="device__container">
        <p className="device__title">
          <span><Link to="/settings">Settings</Link> {'>'} <b>Dashboard Setting</b></span>
          <br/>
          Dashboard Setting
        </p>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableRow} align="left">Sr. No.</TableCell>
                <TableCell className={classes.tableRow} align="left">Title</TableCell>
                <TableCell className={classes.tableRow} align="right">Hide / Show</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cardsForTable && cardsForTable.map((row, index) => (
                <TableRow key={row.pin}>
                  <TableCell align="left" style={{textTransform: "capitalize"}}>{index + 1}</TableCell>
                  <TableCell align="left" style={{textTransform: "capitalize"}}>{row.title}</TableCell>
                  <TableCell align="right">
                    <AntSwitch
                      // checked={check}
                      defaultChecked={row.show}
                      onChange={() => handleChange(row, index+1)}
                      name="checkedC"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </GlobalLayout>
  );
}

const mapStateToProps = state => ({
  cardsForTable: state.settings.cardsForTable,
})

const mapDispatchToProps = dispatch => ({
  getAllDashboardCardsForTable: () => dispatch(settings.getAllDashboardCardsForTable()),
  toggleShowCards: (data) => dispatch(settings.toggleShowCards(data))                                                                                                                                                                
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSetting);
