import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

// import { store } from "./store"
import { Provider } from 'react-redux'
import reducers from './reducers'
import Routes from './routes'
import { loginUserSuccess } from './actions/auth'

import './assets/scss/index.scss' 

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk))
)


let token = localStorage.getItem('Token')
let user = JSON.parse(localStorage.getItem('user'))

if (token) {
    store.dispatch(loginUserSuccess(token, user))
}

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(
        <Provider store={store}>
            <Routes />
        </Provider>,
        rootElement
    )
} else {
    ReactDOM.hydrate(
        <Provider store={store}>
            <Routes />
        </Provider>,
        rootElement
    )
}

// ReactDOM.render(
//     <Provider store={store}>
//         <Routes />
//     </Provider>,
//     document.getElementById('root')
// )

serviceWorker.unregister()
