import React from "react";
import Helmet from "react-helmet";

import { SITENAME, DESCRIPTION } from "../../../constants/data";

import favicon from "../../../assets/img/feedback-app-favicon.png";

const SEO = (props) => {
  const title = (
    typeof props.title === "undefined" ? "" : props.title + " | "
  ).concat(SITENAME);
  const metaDescription = props.description || DESCRIPTION;
  const meta = props.meta || DESCRIPTION;

  return (
    <Helmet
      title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        {
          rel: "icon",
          type: "image/png",
          href: favicon,
        },
      ]}
      meta={[
        {
          name: `title`,
          content: title,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: title,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

export default SEO;
