import React, { useRef, useEffect, useState} from 'react';

// Material UI 

import {
  TextField,
  Button,
  CircularProgress
} from "@material-ui/core";

// Antd Design

import { message } from "antd";

// React Router 
import { Link } from "react-router-dom";

// Formik
import { Formik } from "formik";

// React Redux
import { connect } from "react-redux";
import { settings } from "../../actions"

// Local Imports
import GlobalLayout from "../generics/layout";

// stylesheet

import "./styles/index.scss"

function TextSetting({getAllTextMessage, textSetting, updateAllTextMessage}) {

  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (values) => {
    setLoading(true)
    updateAllTextMessage(values)
      .then(res => {
        message.success("Settings updated successfully.")
        setLoading(false)
      })
      .catch(err => {
        message.error("Something went wrong.")
        setLoading(false)

      })
  }

  useEffect(() => {
    getAllTextMessage()
  },[])

  const data = {
    welcome_msg: "Helo",
    thankyou_msg: "Yo bro !"
  }

  return (
    <GlobalLayout>
      <div className="text__container">
        <div className="text__header">
          <p className="text__title">
            <span><Link to="/settings">Settings</Link> {'>'} <b>Text Setting</b></span>
            <br/>
            Text Setting
          </p>
          <Button
            variant="contained"
            className="text__btn"
            onClick={() => {
              formRef.current.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
              );
            }}
            endIcon={loading ? <CircularProgress size={15} color="inherit" /> : ""}
          >
            Update
          </Button>
        </div>

        {
          textSetting && 
          <Formik
            initialValues = {textSetting}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              errors 
            }) => 
              <form ref={formRef} onSubmit={handleSubmit}>
                <TextField 
                  name="welcome_msg"
                  placeholder="Welcome Message"
                  label="Welcome Message"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.welcome_msg}
                />
                <TextField 
                  name="thankyou_msg"
                  placeholder="Thank You Message"
                  label="Thank You Message"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.thankyou_msg}
                />
                <TextField 
                  name="suggestion_msg"
                  placeholder="Suggestion Message"
                  label="Suggestion Message"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.suggestion_msg}
                />
                <TextField 
                  name="table_room_msg"
                  placeholder="Table Room Message"
                  label="Table Room Message"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.table_room_msg}
                />
                <TextField 
                  name="amount_msg"
                  placeholder="Amount Message"
                  label="Amount Message"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.amount_msg}
                />
                <TextField 
                  name="employee_store"
                  placeholder="Employee Store"
                  label="Employee Store"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.employee_store}
                />
                <TextField 
                  name="disclaimer"
                  placeholder="Disclaimer"
                  label="Disclaimer"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.disclaimer}
                />
                <TextField 
                  name="open_feedback_ss1"
                  placeholder="Open Feedback SS1"
                  label="Open Feedback SS1"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.open_feedback_ss1}
                />
                <TextField 
                  name="open_feedback_ss2"
                  placeholder="Open Feedback SS2"
                  label="Open Feedback SS1"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.open_feedback_ss2}
                />
                <TextField 
                  name="open_feedback_ss3"
                  placeholder="Open Feedback SS3"
                  label="Open Feedback SS3"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.open_feedback_ss3}
                />
                <TextField 
                  name="open_feedback_ss4"
                  placeholder="Open Feedback SS4"
                  label="Open Feedback SS1"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.open_feedback_ss4}
                />
                <TextField 
                  name="open_feedback_ss5"
                  placeholder="Open Feedback SS5"
                  label="Open Feedback SS3"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.open_feedback_ss5}
                />
                <TextField 
                  name="open_feedback_multiple_dec"
                  placeholder="Open Feedback Multiple Description"
                  label="Open Feedback Multiple Description"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.open_feedback_multiple_dec}
                />
                <TextField 
                  name="open_feedback_mode_timeout"
                  placeholder="Open Feedback Mode Timeout"
                  label="Open Feedback Mode Timeout"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.open_feedback_mode_timeout}
                />
                <TextField 
                  name="owner_name"
                  placeholder="Owner Name"
                  label="Owner Name"
                  variant="outlined"
                  fullWidth
                  className="text__field"
                  onChange={handleChange}
                  value={values.owner_name}
                />


              </form>
            }
          </Formik>
        }
      </div>
    </GlobalLayout>
  );
}

const mapStateToProps = state => ({
  textSetting: state.settings.textSetting
})

const mapDispatchToProps = dispatch => ({
  getAllTextMessage: () => dispatch(settings.getAllTextMessage()),
  updateAllTextMessage: (data) => dispatch(settings.updateAllTextMessage(data))
})


export default connect(mapStateToProps, mapDispatchToProps)(TextSetting)
