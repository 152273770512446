import axios from 'axios'

import {
    ALL_TEMPLATES
} from '../constants/feedbackForm'

export const getAllFeedbackFormTemplates = () => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_API_URL}/feedbacks/apiv2/feedback-form/template-form/`,
                { headers }
            )

            if (response.status === 200) {
                dispatch({ type: ALL_TEMPLATES, data: response.data.data })

            }
        }
        catch (error) {
            throw error
        }
    }
}