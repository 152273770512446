import {
    ALL_TEMPLATES,
} from '../constants/feedbackForm'

const initialState = {
    allTemplates: null
}

export default function feedbackFormTemplates(state = initialState, action) {
    switch (action.type) {
        case ALL_TEMPLATES:
            return { ...state, allTemplates: action.data }

        default:
            return state;
    }
}