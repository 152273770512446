import {
    FETCH_SMS_TEMPLATES,
    FETCH_SMS_BALANCE,
} from '../constants/sms'


const initialState = {
    smsTemplates: null,
    smsBalance: null,
}

export default function customers(state = initialState, action) {
    switch (action.type) {
        case FETCH_SMS_TEMPLATES:
            return { ...state, smsTemplates: action.data }
        
        case FETCH_SMS_BALANCE:
            return {...state, smsBalance:action.data}

        default:
            return state
    }
}