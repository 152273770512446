import React, { useState } from 'react'
import { NavLink as Link } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import { Icon } from '@ant-design/compatible';
import { SideMenu } from '../../../constants/data'
import styles from './sidebar.module.scss'

const SideBar = props => {
    const [collapsed, setCollapsed] = useState(true)
    const showSidebar = () => {
        setCollapsed(false)
    }
    const hideSidebar = () => {
        setCollapsed(true)
    }

    return (
        <Layout.Sider
            // collapsible
            collapsed={collapsed}
            className={styles.sidebar}
            style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                height: '100vh',
                position: 'sticky',
                left: 0,
                top: 0,
                
            }}
        >
            <Menu
                onMouseEnter={() => showSidebar()}
                onMouseLeave={() => hideSidebar()}
                theme='dark'
                mode='inline'
                style={{
                    marginTop: '4rem',
                    height: 'inherit',
                    background: 'rgba(215,215,215,0.1)'
                }}>
                {
                    SideMenu.map(menu => {
                        return (
                            <Menu.Item key={menu.key} className={window.location.pathname === menu.link ? 'ant-menu-item-selected' : ''} >
                                {
                                    menu.external
                                        ?
                                        <a href={menu.link}>
                                            <Icon type={menu.icon} />
                                            <span className={styles.menu_text}>{menu.name}</span>
                                        </a>
                                        :
                                        <Link to={menu.link}>
                                            <Icon type={menu.icon} />
                                            <span className={styles.menu_text}>{menu.name}</span>
                                        </Link>
                                }
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        </Layout.Sider>
    )
}

export default SideBar