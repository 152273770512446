import {
  GET_ALL_FEEDBACK,
  GET_OVERALL_RATING_GRAPH,
  GET_QUESTIONS_TABLE,
  GET_QUESTIONS_GRAPH,
  GET_VENDOR_TABLE_DATA,
} from '../constants/generateReport';

const initialState = {
  allFeedback: null,
  vendorTable: null,
  overallRatingGraph: null,
  questionsTable: null,
  questionsGraph: null,
}

export default function generateReport( state = initialState, action ) {
  switch(action.type){
    case GET_ALL_FEEDBACK:
      return { ...state, allFeedback: action.data}
    
    case GET_VENDOR_TABLE_DATA:
      return { ...state, vendorTable: action.data }

    case GET_QUESTIONS_TABLE:
      return { ...state, questionsTable: action.data}
    
    case GET_QUESTIONS_GRAPH:
      return { ...state, questionsGraph: action.data}

    case GET_OVERALL_RATING_GRAPH:
      return { ...state, overallRatingGraph: action.data }

    default:
      return state
  }
}