import axios from 'axios'
import {
    SMS_BALANCE_HISTORY,
    EMAIL_BALANCE_HISTORY,
    FETCH_ALL_BALANCE_DATA,
} from '../constants/balanceHistory'

const BASE_SMS_API_URL = `${process.env.REACT_APP_API_URL}/sms/apiv2`
const BASE_EMAIL_API_URL = `${process.env.REACT_APP_API_URL}/mails/apiv2`

export const fetchSMSBalanceHistory = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }

        try {
            let response = await axios.get(
                `${BASE_SMS_API_URL}/balance/history/?${data}`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: SMS_BALANCE_HISTORY, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}

export const fetchEmailBalanceHistory = (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }

        try {
            let response = await axios.get(
                `${BASE_EMAIL_API_URL}/balance/history/?${data}`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: EMAIL_BALANCE_HISTORY, data: response.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}


export const fetchAllBalance = () => async (dispatch, getState) => {
    const token = getState().auth.token
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        }

        try {
            let response = await axios.get(
                `${BASE_SMS_API_URL}/all-balance/`,
                { headers }
            )
            if (response.status === 200) {
                dispatch({ type: FETCH_ALL_BALANCE_DATA, data: response.data.data })
            }
        }
        catch (error) {
            throw error
        }
    }
}