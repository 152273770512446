import axios from "axios";

import {
  FETCH_ALL_MEMBERS,
  FETCH_PLAN_TYPE,
  ADD_PRIMARY_MEMBER,
  FETCH_COUPON_DETAILS,
  FETCH_MEMBERS_STAT,
  COUPONS_MEMBERS_LIST,
  FETCH_MEMBER_DETAILS,
  FETCH_DOCUMENTS,
  FETCH_SECONDARY_MEMBERS
} from "../constants/members";

//Add http://13.234.180.43:8000/members/apiv2/members/ to

const MEMBER_BASE_URL = `${process.env.REACT_APP_API_URL}/members/apiv2`;
export const fetchAllMembers = (data) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { "Content-Type": "application/json" };
  //let token = '8bd2bdf3c9a314c548c5917c59f61c4d370c6527'
  if (token) {
    headers["Authorization"] = `Token ${token}`;

    try {
      let response = await axios.get(`${MEMBER_BASE_URL}/members/?${data}`, {
        headers,
      });
      if (response.status === 200) {
        return dispatch({ type: FETCH_ALL_MEMBERS, data: response.data });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const fetchMembersStat = () => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { "Content-Type": "application/json" };

  if (token) {
    headers["Authorization"] = `Token ${token}`;
    try {
      let response = await axios.get(`${MEMBER_BASE_URL}/members/stats/`, {
        headers,
      });
      if (response.status === 200) {
        return dispatch({ type: FETCH_MEMBERS_STAT, data: response.data });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const fetchPlanTypeList = () => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { "Content-Type": "application/json" };

  if (token) {
    headers["Authorization"] = `Token ${token}`;
    try {
      let response = await axios.get(`${MEMBER_BASE_URL}/member-plan-types/`, {
        headers,
      });
      if (response.status === 200) {
        return dispatch({ type: FETCH_PLAN_TYPE, data: response.data });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const addPrimaryMember = (data) => async (dispatch, getState) => {
  let token = getState().auth.token;

  let headers = { "Content-Type": "application/json" };
  if (token) {
    headers["Authorization"] = `Token ${token}`;
    try {
      let response = await axios.post(`${MEMBER_BASE_URL}/members/`, data, {
        headers,
      });
      // console.log(response.data)
      // return response.data

      if (response.status === 201) {
        dispatch({ type: ADD_PRIMARY_MEMBER, data: response.data });
      }

      return response;
    } catch (error) {
      return error;
    }
  }
};

export const addSecondaryMember = (data, id) => async (dispatch, getState) => {
  let token = getState().auth.token;

  let headers = { "Content-Type": "application/json" };
  if (token) {
    headers["Authorization"] = `Token ${token}`;
    try {
      let response = await axios.post(
        `${MEMBER_BASE_URL}/members/${id}/add-secondary-member/`,
        data,
        { headers }
      );

      return response;
    } catch (error) {
      
      return error;
    }
  }
};

export const fetchCouponDetails = (data) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { "Content-Type": "application/json" };
  //let token = '8bd2bdf3c9a314c548c5917c59f61c4d370c6527'
  if (token) {
    headers["Authorization"] = `Token ${token}`;

    try {
      let response = await axios.get(
        `${MEMBER_BASE_URL}/coupon-usages/?${data}`,
        { headers }
      );
      if (response.status === 200) {
        return dispatch({ type: FETCH_COUPON_DETAILS, data: response.data });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const handleUploadDocuments =
  (data, id) => async (dispatch, getState) => {
    let token = getState().auth.token;

    let headers = { "Content-Type": "multipart/form-data" };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
      try {
        let response = await axios.post(
          `${MEMBER_BASE_URL}/members/${id}/upload-documents/?`,
          data,
          { headers }
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    }
  };

export const getMembersListForCoupons = () => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { "Content-Type": "application/json" };

  if (token) {
    headers["Authorization"] = `Token ${token}`;
    try {
      let response = await axios.get(`${MEMBER_BASE_URL}/coupon-redeem/`, {
        headers,
      });
      if (response.status === 200) {
        return dispatch({ type: COUPONS_MEMBERS_LIST, data: response.data });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const redeemCoupon = (data, id) => async (dispatch, getState) => {
  let token = getState().auth.token;
  console.log(data);
  let headers = { "Content-Type": "application/json" };
  if (token) {
    headers["Authorization"] = `Token ${token}`;
    try {
      let response = await axios.post(
        `${MEMBER_BASE_URL}/coupon-redeem/${id}/redeem/? `,
        JSON.stringify(data),
        { headers }
      );

      return response;
    } catch (error) {
      return error;
    }
  }
};

export const getOtp = (id) => async (dispatch, getState) => {
  let token = getState().auth.token;

  let headers = { "Content-Type": "application/json" };
  if (token) {
    headers["Authorization"] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${MEMBER_BASE_URL}/coupon-redeem/${id}/get-otp/?`,
        { headers }
      );

      return response;
    } catch (error) {
      throw error;
    }
  }
};

export const getMemberDetails = (data) => async (dispatch, getState) => {
  let token = getState().auth.token;
  let headers = { "Content-Type": "application/json" };
  //let token = '8bd2bdf3c9a314c548c5917c59f61c4d370c6527'
  if (token) {
    headers["Authorization"] = `Token ${token}`;

    try {
      let response = await axios.get(`${MEMBER_BASE_URL}/members/${data}/`, {
        headers,
      });
      if (response.status === 200) {
               
        return dispatch({ type: FETCH_MEMBER_DETAILS, data: response.data })
    }

    } catch (error) {
      throw error;
    }
  }
};





export const updateMemberDetail = (data, id) => async(dispatch, getState) => {
    
    let token = getState().auth.token;
  let headers = { "Content-Type": "application/json" };
    if(token){
      headers['Authorization'] = `Token ${token}`;
      try {
        let response = await axios.patch(
          `${MEMBER_BASE_URL}/members/${id}/?`,
          data,
          { headers }
        )
        
        
    
          return response;
  
      } catch(err) {
        throw err
      }
    }
  }


  export const getDocuments = (id) => async (dispatch, getState) => {
    let token = getState().auth.token;
    let headers = { "Content-Type": "application/json" };
    //let token = '8bd2bdf3c9a314c548c5917c59f61c4d370c6527'
    if (token) {
      headers["Authorization"] = `Token ${token}`;
  
      try {
        let response = await axios.get(`${MEMBER_BASE_URL}/members/${id}/documents/`, {
          headers,
        });
        if (response.status === 200) {
                 
          return dispatch({ type: FETCH_DOCUMENTS, data: response.data })
      }
  
      } catch (error) {
        throw error;
      }
    }
  };


  
  export const deleteFile = (id,fileId) => async (dispatch, getState) => {
    let token = getState().auth.token;
    console.log(token)
    let headers = { "Content-Type": "application/json" };
  
    if (token) {
      headers["Authorization"] = `Token ${token}`;
  
      try {
        let response = await axios.delete(`${MEMBER_BASE_URL}/members/${id}/documents/${fileId}/?`, {
          headers,
        });
        if (response.status === 200) {
                 
          return 
      }
  
      } catch (error) {
        throw error;
      }
    }
  };


  export const getSecondaryMember = (memeberId) => async (dispatch, getState) => {
    let token = getState().auth.token;
    let headers = { "Content-Type": "application/json" };
    //let token = '8bd2bdf3c9a314c548c5917c59f61c4d370c6527'
    if (token) {
      headers["Authorization"] = `Token ${token}`;
  
      try {
        let response = await axios.get(`${MEMBER_BASE_URL}/members/${memeberId}/secondary-members/`, {
          headers,
        });
        if (response.status === 200) {
          console.log(response)
                 
          return dispatch({ type: FETCH_SECONDARY_MEMBERS, data: response.data })
      }
  
      } catch (error) {
        throw error;
      }
    }
  };


  export const deleteSecondaryMember = (memberId,id) => async (dispatch, getState) => {
    let token = getState().auth.token;
    let data
    console.log(token)
    let headers = { "Content-Type": "application/json" };
    //let token = '8bd2bdf3c9a314c548c5917c59f61c4d370c6527'
    if (token) {
      headers["Authorization"] = `Token ${token}`;
      console.log(headers)
      try {
        let response = await axios.post(`${MEMBER_BASE_URL}/members/${memberId}/secondary-members/${id}/?`,'', {
          headers, 
        });
        if (response.status === 200) {
                 
          console.log(response)
      }
  
      } catch (error) {
        throw error;
      }
    }
  };


  // export const addSecondaryMember = (data, id) => async (dispatch, getState) => {
  //   let token = getState().auth.token;
  
  //   let headers = { "Content-Type": "application/json" };
  //   if (token) {
  //     headers["Authorization"] = `Token ${token}`;
  //     try {
  //       let response = await axios.post(
  //         `${MEMBER_BASE_URL}/members/${id}/add-secondary-member/`,
  //         data,
  //         { headers }
  //       );
  
  //       return response;
  //     } catch (error) {
        
  //       return error;
  //     }
  //   }
  // };